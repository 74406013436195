import React, { FC, useState, useMemo, useEffect } from 'react';
import { MentionRow } from '../MentionRow/MentionRow';
import { ExplorerAddressWallet } from '../../../explorer';
import { getNetworkEnum } from '@helpers/address';
import { useTranslation } from 'react-i18next';
import { Typography, LoaderOverlay, Informer } from '@rubin-dev/goblin';
import styles from './mention.module.scss';
import { Screenshots } from '../../../../screen/Markup/Screenshots';
import { RiskDot } from '@component/NewRisk';
import cx from 'classnames';
import {
  useExplorerFindMentionLazyQuery,
  useExplorerMarkupQuery,
  useExplorerRiskLazyQuery,
  useMeQuery,
} from '@apolloGenerated';
import { SubscribeButton } from '@component/Mention';
import { RouterName } from '../../../../router';
import { Blur } from '@shared/ui';
import sdk from '@aml/sdk';

type MentionProps = {
  network: string;
  address: string;
  isLoadingMarkup?: boolean;
  id?: number;
  subscribeCallback?: () => void;
  setMention?: (mention: unknown) => void;
  showHeader?: boolean;
};

export const MentionComponent: FC<MentionProps> = ({
  network,
  address,
  isLoadingMarkup = false,
  id,
  subscribeCallback,
  showHeader = true,
  setMention,
}) => {
  const { t } = useTranslation();
  const [link, setLink] = useState<string>('');
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  const [fetchRiskData, { data: explorerRiskData, loading: loadingExplorer }] =
    useExplorerRiskLazyQuery();

  const [fetchFindMention, { data: findMention, loading: findMentionsLoading }] =
    useExplorerFindMentionLazyQuery();
  const {
    data,
    loading,
    error: errorMarkup,
  } = useExplorerMarkupQuery({
    variables: { network: getNetworkEnum(network), address },
  });

  const { data: categoryDict } = sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  const mentions = data?.explorerMarkup?.mention;
  const report = data?.explorerMarkup?.report;

  const mention = useMemo(() => {
    return id
      ? {
          ...findMention?.explorerFindMention.mention,
          categoryId: findMention?.explorerFindMention.mention?.risk?.categoryNumber,
        }
      : { ...mentions, ...report };
  }, [findMention?.explorerFindMention.mention, mentions, report]);

  const isLoading = useMemo(
    () => loadingExplorer || findMentionsLoading || loading || isLoadingMarkup,
    [loadingExplorer, findMentionsLoading, loading, isLoadingMarkup],
  );

  const categories = explorerRiskData?.explorerAddressCategories?.categories;

  const screenshots = useMemo(() => {
    const screenshots = id
      ? findMention?.explorerFindMention.screenshots
      : data?.explorerMarkup?.screenshots;

    return screenshots || [];
  }, [findMention?.explorerFindMention.mention, mentions, report]);

  useEffect(() => {
    if (id) {
      fetchFindMention({
        variables: {
          id,
          network: getNetworkEnum(network),
        },
      });
    }

    if (mention) {
      fetchRiskData({
        variables: {
          network: getNetworkEnum(network),
          address,
        },
      });
    }
  }, [network, id]);

  useEffect(() => {
    if (setMention) {
      setMention(mention);
    }
  }, [mention]);

  const risk = useMemo(() => {
    return Object.values(categoryDict?.params || {})?.find(
      (item) => item.number === mention?.risk?.categoryNumber,
    )?.risk;
  }, [categoryDict?.params, mention]);

  if (isLoading) {
    return <LoaderOverlay />;
  }

  if ((errorMarkup || !Object.values(mention)?.length) && !isLoading) {
    return (
      <div className={styles['mention__not-found']}>
        <Informer
          type="empty"
          title={t('titles.addressNotFound')}
          subtitle={t('explorer.markup_subscription')}
        />
        <SubscribeButton
          address={address}
          network={network}
          subscribeCallback={() => subscribeCallback && subscribeCallback()}
        />
      </div>
    );
  }

  return (
    <div className={styles['mention']}>
      {showHeader && (
        <div className={styles['mention__header']}>
          <Typography variant={'head-32'} className={styles['mention__title']}>
            {t('titles.details')}
          </Typography>
        </div>
      )}
      <div className={styles['mention__wrapper']}>
        <MentionRow title={t('links.category')} color={'error-2'}>
          {categories?.find((item) => item.number === mention?.risk?.categoryNumber)
            ?.name || '-'}
        </MentionRow>
        <MentionRow title={t('strings.riskscore')}>
          {risk ? (
            <div className={cx(styles['mention__item'], styles['mention__item_risk'])}>
              <RiskDot risk={Number(risk)} /> {risk}%
            </div>
          ) : (
            '-'
          )}
        </MentionRow>
        <MentionRow title={t('mention.cluster')} copyValue={String(link)}>
          {!expireDate ? (
            <Blur to={RouterName.TariffAnalyzer}>
              <Typography variant="head-16" color="primary-1">
                Cluster
              </Typography>
            </Blur>
          ) : (
            <ExplorerAddressWallet
              network={getNetworkEnum(network)}
              address={address}
              hideTitle={true}
              hideLink={false}
              target={'_blank'}
              emptyText={'Entity'}
              setLink={setLink}
            />
          )}
        </MentionRow>
        <MentionRow title={t('mention.anotherAddresses')}>
          <div>
            {mention?.otherAddresses &&
              Object.values(mention?.otherAddresses).length > 0 &&
              Object.entries(mention?.otherAddresses).map(([key, value]) => (
                <Typography variant={'body-14'} key={key}>
                  {value?.network ? value?.network.toUpperCase() + ' - ' : ''}
                  {value?.address || ''}
                </Typography>
              ))}
          </div>
        </MentionRow>
        <MentionRow title={t('strings.owner')}>{mention?.owner ?? '-'}</MentionRow>
        <MentionRow title={t('mention.description')}>
          {mention?.description ?? '-'}
        </MentionRow>
        <MentionRow
          title={t('mention.forumRef')}
          link={!!mention?.link}
          href={mention?.link}
          copyValue={mention?.link}
        >
          {mention?.link ?? '-'}
        </MentionRow>
        {screenshots?.length > 0 && (
          <MentionRow title={t('titles.source')}>
            <Screenshots screenshots={screenshots} loading={isLoadingMarkup} />
          </MentionRow>
        )}
      </div>
    </div>
  );
};
