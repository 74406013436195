import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Int32: { input: any; output: any };
  Int64: { input: any; output: any };
  Integer: { input: any; output: any };
  JSON: { input: any; output: any };
  Map: { input: any; output: any };
  UUID: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type ActivateInput = {
  userId: Scalars['ID']['input'];
};

export type ActivateResponse = {
  __typename?: 'ActivateResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  state: Scalars['Boolean']['output'];
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String']['output'];
  cluster?: Maybe<Cluster>;
  network: Network;
  owner?: Maybe<Scalars['String']['output']>;
};

export type AddressActivity = {
  __typename?: 'AddressActivity';
  first: Scalars['Int64']['output'];
  last: Scalars['Int64']['output'];
};

export type AddressActivityResponse = {
  __typename?: 'AddressActivityResponse';
  activity?: Maybe<AddressActivity>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type AddressAmountFilter = {
  from?: InputMaybe<Scalars['Int64']['input']>;
  to?: InputMaybe<Scalars['Int64']['input']>;
};

export type AddressByTransaction = {
  __typename?: 'AddressByTransaction';
  address: Scalars['String']['output'];
  cluster?: Maybe<Cluster>;
  firstAppear: Scalars['Int64']['output'];
  hexAddress: Scalars['String']['output'];
  network: Network;
  summ: Scalars['String']['output'];
  token: Scalars['String']['output'];
  totalIn: Scalars['String']['output'];
};

export type AddressByTransactionListResponse = {
  __typename?: 'AddressByTransactionListResponse';
  edge: Array<AddressByTransaction>;
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AddressCategoriesResponse = {
  __typename?: 'AddressCategoriesResponse';
  categories?: Maybe<Array<RiskCategory>>;
  /** @deprecated Field no longer supported */
  errors?: Maybe<Scalars['Any']['output']>;
};

export type AddressChart = {
  __typename?: 'AddressChart';
  address?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  percent?: Maybe<Scalars['String']['output']>;
  received?: Maybe<Scalars['String']['output']>;
  reported?: Maybe<Scalars['Boolean']['output']>;
  rescat?: Maybe<Scalars['Int32']['output']>;
  resdrt?: Maybe<Scalars['String']['output']>;
  wid?: Maybe<Scalars['String']['output']>;
};

export type AddressChartResponse = {
  __typename?: 'AddressChartResponse';
  charts: Array<AddressChart>;
  /** @deprecated Field no longer supported */
  errors?: Maybe<Scalars['Any']['output']>;
};

export type AddressCheckedInput = {
  source: Scalars['String']['input'];
  target: Scalars['String']['input'];
  txid: Scalars['String']['input'];
  type: AddressTransactionsFilterTxType;
};

export type AddressCheckedType = {
  __typename?: 'AddressCheckedType';
  source: Scalars['String']['output'];
  target: Scalars['String']['output'];
  txid: Scalars['String']['output'];
  type: AddressTransactionsFilterTxType;
};

export type AddressTimestampFilter = {
  from?: InputMaybe<Scalars['Int64']['input']>;
  to?: InputMaybe<Scalars['Int64']['input']>;
};

export type AddressTransaction = {
  __typename?: 'AddressTransaction';
  amount: Scalars['String']['output'];
  direction: Scalars['String']['output'];
  recvAddr: Array<Address>;
  senderAddr: Array<Address>;
  timestamp: Scalars['Int64']['output'];
  token: Scalars['String']['output'];
  txID: Scalars['String']['output'];
};

export type AddressTransactionStats = {
  __typename?: 'AddressTransactionStats';
  received: Scalars['String']['output'];
  sent: Scalars['String']['output'];
  total: Scalars['String']['output'];
};

export type AddressTransactionStatsResponse = {
  __typename?: 'AddressTransactionStatsResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  stats?: Maybe<AddressTransactionStats>;
};

export type AddressTransactionsCountFilter = {
  address: Scalars['String']['input'];
  amountFilter?: InputMaybe<AddressAmountFilter>;
  timestampFilter?: InputMaybe<AddressTimestampFilter>;
  txType?: InputMaybe<AddressTransactionsFilterTxType>;
};

export type AddressTransactionsCountResponse = {
  __typename?: 'AddressTransactionsCountResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['String']['output']>;
};

export type AddressTransactionsFilter = {
  address: Scalars['String']['input'];
  amountFilter?: InputMaybe<AddressAmountFilter>;
  order?: InputMaybe<AddressTransactionsOrder>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  timestampFilter?: InputMaybe<AddressTimestampFilter>;
  txType?: InputMaybe<AddressTransactionsFilterTxType>;
};

export enum AddressTransactionsFilterTxType {
  All = 'all',
  Receives = 'receives',
  Sent = 'sent',
}

export type AddressTransactionsOrder = {
  direction: OrderDirection;
  field: AddressTransactionsOrderField;
};

export enum AddressTransactionsOrderField {
  Amount = 'amount',
  Timestamp = 'timestamp',
}

export type AddressTransactionsResponse = {
  __typename?: 'AddressTransactionsResponse';
  edge?: Maybe<Array<AddressTransaction>>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type AmountType = {
  __typename?: 'AmountType';
  txid: Scalars['String']['output'];
  type: AddressTransactionsFilterTxType;
  value: Scalars['Float']['output'];
};

export type AmountTypeInput = {
  txid: Scalars['String']['input'];
  type: AddressTransactionsFilterTxType;
  value: Scalars['Float']['input'];
};

export type Annotation = {
  __typename?: 'Annotation';
  text: Scalars['String']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type AnnotationInput = {
  text: Scalars['String']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type Billing = {
  __typename?: 'Billing';
  address: Scalars['Int64']['output'];
  analyzerSubscriptionExpireDate?: Maybe<Scalars['DateTime']['output']>;
  markup: Scalars['Int64']['output'];
  transactions: Scalars['Int64']['output'];
};

export type BillingKey = {
  __typename?: 'BillingKey';
  createdAt: Scalars['DateTime']['output'];
  key: Scalars['String']['output'];
};

export type BillingKeyResponse = {
  __typename?: 'BillingKeyResponse';
  edge: Array<BillingKey>;
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum BillingRequestType {
  Address = 'Address',
  Markup = 'Markup',
  Transaction = 'Transaction',
}

export type BlockResponse = {
  __typename?: 'BlockResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  node?: Maybe<BlockchainBlock>;
};

export type BlockchainBlock = {
  __typename?: 'BlockchainBlock';
  hash: Scalars['String']['output'];
  height: Scalars['Int64']['output'];
  timestamp?: Maybe<Scalars['Int64']['output']>;
};

export type CalculateItem = {
  __typename?: 'CalculateItem';
  color: Scalars['String']['output'];
  i18n: DirectoryI18n;
  name: Scalars['String']['output'];
  number: Scalars['Int32']['output'];
  percent: Scalars['Float']['output'];
  percent_raw: Scalars['Float']['output'];
  risk: Scalars['Float']['output'];
  risk_raw: Scalars['Float']['output'];
  total: Scalars['String']['output'];
  total_human: Scalars['String']['output'];
};

export type CalculatedRisk = {
  __typename?: 'CalculatedRisk';
  items: Array<CalculateItem>;
  risk: Scalars['Float']['output'];
  total: Scalars['String']['output'];
  total_human: Scalars['String']['output'];
};

export type ChangePasswordInput = {
  password: Scalars['String']['input'];
  passwordConfirm: Scalars['String']['input'];
  passwordCurrent: Scalars['String']['input'];
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  jwt?: Maybe<Jwt>;
};

export type Cluster = {
  __typename?: 'Cluster';
  id: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  riskCategory?: Maybe<Scalars['Int32']['output']>;
  riskVersion: Scalars['Int32']['output'];
};

export enum ClusterClusterTransactionsFilterTxType {
  Receives = 'receives',
  Sent = 'sent',
}

export type ClusterDetails = {
  __typename?: 'ClusterDetails';
  lastActivity: Scalars['Int64']['output'];
  reported: Scalars['Boolean']['output'];
  totalIn: Scalars['String']['output'];
  totalOut: Scalars['String']['output'];
  transactions: Scalars['String']['output'];
};

export type ClusterDetailsResponse = {
  __typename?: 'ClusterDetailsResponse';
  entity?: Maybe<ClusterDetails>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type ClusterInput = {
  id: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
  riskCategory?: InputMaybe<Scalars['Int32']['input']>;
  riskVersion: Scalars['Int32']['input'];
};

export type ClusterResponse = {
  __typename?: 'ClusterResponse';
  cluster?: Maybe<Cluster>;
};

export type ClusterStats = {
  __typename?: 'ClusterStats';
  addressCount: Scalars['String']['output'];
  firstTx: Scalars['Int64']['output'];
  lastTx: Scalars['Int64']['output'];
  receivedAmount: Scalars['String']['output'];
  receivedCount: Scalars['String']['output'];
  sentAmount: Scalars['String']['output'];
  sentCount: Scalars['String']['output'];
  totalAmount: Scalars['String']['output'];
  totalCount: Scalars['String']['output'];
};

export type ClusterStatsResponse = {
  __typename?: 'ClusterStatsResponse';
  stats?: Maybe<ClusterStats>;
};

export type ClusterTransactionsCountFilter = {
  amountFilter?: InputMaybe<AddressAmountFilter>;
  cluster: Scalars['String']['input'];
  timestampFilter?: InputMaybe<AddressTimestampFilter>;
  txType?: InputMaybe<AddressTransactionsFilterTxType>;
};

export type ClusterTransactionsCountResponse = {
  __typename?: 'ClusterTransactionsCountResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['String']['output']>;
};

export type ClusterTransactionsFilter = {
  amountFilter?: InputMaybe<AddressAmountFilter>;
  cluster: Scalars['String']['input'];
  order?: InputMaybe<AddressTransactionsOrder>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  timestampFilter?: InputMaybe<AddressTimestampFilter>;
  txType?: InputMaybe<AddressTransactionsFilterTxType>;
};

export type ClusterTransactionsResponse = {
  __typename?: 'ClusterTransactionsResponse';
  edge?: Maybe<Array<AddressTransaction>>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type CreateInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  permissions: Array<Scalars['String']['input']>;
};

export type CreateResponse = {
  __typename?: 'CreateResponse';
  email: Scalars['String']['output'];
  errors?: Maybe<Scalars['Any']['output']>;
  password: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
};

export type DeleteInput = {
  userId: Scalars['ID']['input'];
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  state: Scalars['Boolean']['output'];
};

export type DirectoryI18n = {
  __typename?: 'DirectoryI18n';
  en: Scalars['String']['output'];
  ru: Scalars['String']['output'];
};

export type ExplorerMarkupCreateInput = {
  address: Scalars['String']['input'];
  categoryNumber?: InputMaybe<Scalars['Int32']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int32']['input']>;
  link: Scalars['String']['input'];
  mentionSourceID: Scalars['Int32']['input'];
  network: Network;
  otherAddresses: Array<OtherAddressInput>;
  owner?: InputMaybe<Scalars['String']['input']>;
  screenshots?: InputMaybe<Array<Scalars['Upload']['input']>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ExplorerRelation = {
  __typename?: 'ExplorerRelation';
  address: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  direction: Scalars['String']['output'];
  timestamp: Scalars['Int64']['output'];
  token: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type ExplorerRelationBetweenAddressesReq = {
  addresses: Array<Scalars['String']['input']>;
  network: Network;
  newAddress: Scalars['String']['input'];
};

export type ExplorerRelationBetweenAddressesResp = {
  __typename?: 'ExplorerRelationBetweenAddressesResp';
  relations: Array<ExplorerRelation>;
};

export type FindAddressByHashNode = {
  __typename?: 'FindAddressByHashNode';
  total: Scalars['String']['output'];
  totalIn: Scalars['String']['output'];
  totalOut: Scalars['String']['output'];
};

export type FindAddressByHashNodeResponse = {
  __typename?: 'FindAddressByHashNodeResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  node?: Maybe<FindAddressByHashNode>;
};

export type GeneratePasswordInput = {
  userId: Scalars['ID']['input'];
};

export type GeneratePasswordResponse = {
  __typename?: 'GeneratePasswordResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  password: Scalars['String']['output'];
};

export type Graph = {
  __typename?: 'Graph';
  links: Array<GraphLink>;
  nodes: Array<GraphNode>;
};

export type GraphData = {
  __typename?: 'GraphData';
  createdAt: Scalars['Int64']['output'];
  data: GraphSaveData;
  updatedAt: Scalars['Int64']['output'];
  uuid: Scalars['UUID']['output'];
};

export type GraphDataInput = {
  data: GraphSaveDataInput;
  uuid: Scalars['UUID']['input'];
};

export type GraphDeleteResponse = {
  __typename?: 'GraphDeleteResponse';
  errors?: Maybe<Scalars['Any']['output']>;
};

export type GraphFindByUuidResponse = {
  __typename?: 'GraphFindByUUIDResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  graph?: Maybe<GraphData>;
};

export type GraphHashes = {
  __typename?: 'GraphHashes';
  hash: Scalars['String']['output'];
  type: TableType;
};

export type GraphHashesInput = {
  hash: Scalars['String']['input'];
  type: TableType;
};

export type GraphInput = {
  links?: InputMaybe<Array<GraphLinkInput>>;
  nodes?: InputMaybe<Array<GraphNodeInput>>;
};

export type GraphLink = {
  __typename?: 'GraphLink';
  amount: Array<AmountType>;
  annotation?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  target: Scalars['String']['output'];
};

export type GraphLinkInput = {
  amount?: InputMaybe<Array<AmountTypeInput>>;
  annotation?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  target: Scalars['String']['input'];
};

export type GraphListResponse = {
  __typename?: 'GraphListResponse';
  edge: Array<GraphData>;
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type GraphMeta = {
  __typename?: 'GraphMeta';
  canvas?: Maybe<ZoomParamsType>;
  current: GraphHashes;
  name: Scalars['String']['output'];
  network: Network;
};

export type GraphMetaInput = {
  canvas?: InputMaybe<ZoomParamsTypeInput>;
  current: GraphHashesInput;
  name: Scalars['String']['input'];
  network: Network;
};

export type GraphMetaNameUpdateResponse = {
  __typename?: 'GraphMetaNameUpdateResponse';
  errors?: Maybe<Scalars['Any']['output']>;
};

export type GraphNode = {
  __typename?: 'GraphNode';
  annotations: Array<Annotation>;
  balance: Scalars['Float']['output'];
  category: Scalars['String']['output'];
  checked: Array<AddressCheckedType>;
  cluster?: Maybe<Cluster>;
  coords?: Maybe<NodeCoords>;
  hasMention: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  reportedCategory?: Maybe<Scalars['String']['output']>;
  risk?: Maybe<Scalars['Float']['output']>;
  tableHash: Scalars['String']['output'];
  total: Scalars['Float']['output'];
  totalIn: Scalars['Float']['output'];
  totalOut: Scalars['Float']['output'];
};

export type GraphNodeInput = {
  annotations: Array<AnnotationInput>;
  balance: Scalars['Float']['input'];
  category: Scalars['String']['input'];
  checked: Array<AddressCheckedInput>;
  cluster?: InputMaybe<ClusterInput>;
  coords?: InputMaybe<NodeCoordsInput>;
  hasMention: Scalars['Boolean']['input'];
  hash: Scalars['String']['input'];
  reportedCategory?: InputMaybe<Scalars['String']['input']>;
  risk?: InputMaybe<Scalars['Float']['input']>;
  tableHash: Scalars['String']['input'];
  total: Scalars['Float']['input'];
  totalIn: Scalars['Float']['input'];
  totalOut: Scalars['Float']['input'];
};

export type GraphSaveData = {
  __typename?: 'GraphSaveData';
  graph: Graph;
  meta: GraphMeta;
};

export type GraphSaveDataInput = {
  graph: GraphInput;
  meta: GraphMetaInput;
};

export type GraphSaveResponse = {
  __typename?: 'GraphSaveResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Jwt = {
  __typename?: 'Jwt';
  accessToken: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  permissions: Array<Scalars['String']['output']>;
  refreshToken: Scalars['String']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  jwt?: Maybe<Jwt>;
};

export type MarkupResponse = {
  __typename?: 'MarkupResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  mention?: Maybe<Mention>;
  report?: Maybe<Report>;
  risk?: Maybe<Risk>;
  screenshots?: Maybe<Array<Scalars['String']['output']>>;
};

export type Mention = {
  __typename?: 'Mention';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  hasScreenshots: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isPaid: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  otherAddresses: Array<OtherAddress>;
  owner: Scalars['String']['output'];
  reportId?: Maybe<Scalars['Int64']['output']>;
  risk?: Maybe<MentionRisk>;
  source: MentionSource;
  uuid: Scalars['UUID']['output'];
};

export type MentionRemoveInput = {
  uuid: Scalars['UUID']['input'];
};

export type MentionResponse = {
  __typename?: 'MentionResponse';
  mention?: Maybe<Mention>;
  screenshots?: Maybe<Array<Scalars['String']['output']>>;
};

export type MentionRisk = {
  __typename?: 'MentionRisk';
  categoryNumber: Scalars['Int32']['output'];
  color: Scalars['String']['output'];
};

export type MentionSource = {
  __typename?: 'MentionSource';
  categoryNumber: Scalars['Int32']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int32']['output'];
  name: Scalars['String']['output'];
  sourceAddress: Scalars['String']['output'];
  version: Scalars['Int32']['output'];
};

export type MentionsResponse = {
  __typename?: 'MentionsResponse';
  edge: Array<Mention>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activate: ActivateResponse;
  billingAddPacket: Scalars['Boolean']['output'];
  billingAnalyzerSubscribe: Scalars['Boolean']['output'];
  billingKeyCreate: BillingKey;
  billingKeyRemove: Scalars['Boolean']['output'];
  changePassword: ChangePasswordResponse;
  create: CreateResponse;
  delete: DeleteResponse;
  explorerMarkupCreate: MentionResponse;
  explorerMentionRemove: Scalars['Boolean']['output'];
  generatePassword: GeneratePasswordResponse;
  graphDelete: GraphDeleteResponse;
  graphMetaNameUpdate: GraphMetaNameUpdateResponse;
  graphSave: GraphSaveResponse;
  graphUpdate: GraphUpdateResponse;
  health: Scalars['Boolean']['output'];
  login: LoginResponse;
  refreshToken: RefreshTokenResponse;
  registration: RegistrationResponse;
  registrationConfirm: RegistrationConfirmResponse;
  restore: RestoreResponse;
  restoreCheck: RestoreResponse;
  restoreConfirm: RestoreConfirmResponse;
  setUserPermissions: SetPermissionsResponse;
  subscribe: Scalars['Boolean']['output'];
  subscriptionMarkAsViewed: Scalars['Boolean']['output'];
  unsubscribe: Scalars['Boolean']['output'];
};

export type MutationActivateArgs = {
  input: ActivateInput;
};

export type MutationBillingAddPacketArgs = {
  requestType: BillingRequestType;
  requests: Scalars['Int64']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationBillingAnalyzerSubscribeArgs = {
  expireDate: Scalars['Int64']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationBillingKeyRemoveArgs = {
  key: Scalars['String']['input'];
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCreateArgs = {
  input: CreateInput;
};

export type MutationDeleteArgs = {
  input: DeleteInput;
};

export type MutationExplorerMarkupCreateArgs = {
  req: ExplorerMarkupCreateInput;
};

export type MutationExplorerMentionRemoveArgs = {
  req: MentionRemoveInput;
};

export type MutationGeneratePasswordArgs = {
  input: GeneratePasswordInput;
};

export type MutationGraphDeleteArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationGraphMetaNameUpdateArgs = {
  metaName: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type MutationGraphSaveArgs = {
  input: GraphDataInput;
};

export type MutationGraphUpdateArgs = {
  input: GraphDataInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationRegistrationArgs = {
  input: RegistrationInput;
};

export type MutationRegistrationConfirmArgs = {
  input: RegistrationConfirmInput;
};

export type MutationRestoreArgs = {
  input: RestoreInput;
};

export type MutationRestoreCheckArgs = {
  input: RestoreCheckInput;
};

export type MutationRestoreConfirmArgs = {
  input: RestoreConfirmInput;
};

export type MutationSetUserPermissionsArgs = {
  input: SetPermissionsInput;
};

export type MutationSubscribeArgs = {
  input: SubscribeInput;
};

export type MutationSubscriptionMarkAsViewedArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUnsubscribeArgs = {
  input: SubscribeInput;
};

export enum Network {
  Btc = 'BTC',
  Eth = 'ETH',
  Tron = 'TRON',
}

export type Node = {
  __typename?: 'Node';
  /** @deprecated Field no longer supported */
  id: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  props?: Maybe<Scalars['Any']['output']>;
};

export type NodeCoords = {
  __typename?: 'NodeCoords';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type NodeCoordsInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type NodeCountResponse = {
  __typename?: 'NodeCountResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  has?: Maybe<Scalars['Boolean']['output']>;
};

export type NodeCursorListResponse = {
  __typename?: 'NodeCursorListResponse';
  cursor: Scalars['Int64']['output'];
  edge: Array<Node>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type NodeEntityResponse = {
  __typename?: 'NodeEntityResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  node?: Maybe<Node>;
};

export type NodeListResponse = {
  __typename?: 'NodeListResponse';
  edge: Array<Node>;
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type OtherAddress = {
  __typename?: 'OtherAddress';
  address: Scalars['String']['output'];
  network: Scalars['String']['output'];
};

export type OtherAddressInput = {
  address: Scalars['String']['input'];
  network: Scalars['String']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['ID']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  startCursor: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  billingKeyList: BillingKeyResponse;
  explorerAddress: Address;
  explorerAddressActivity: AddressActivityResponse;
  /** @deprecated Field no longer supported */
  explorerAddressCategories: AddressCategoriesResponse;
  explorerAddressChart: AddressChartResponse;
  explorerAddressTransactionStats: AddressTransactionStatsResponse;
  explorerCluster: ClusterResponse;
  explorerClusterDetails: ClusterDetailsResponse;
  explorerClusterForAddress: ClusterResponse;
  explorerClusterStats: ClusterStatsResponse;
  explorerFindAddressByHash: FindAddressByHashNodeResponse;
  explorerFindBlockByHash: BlockResponse;
  explorerFindBlockByHeight: BlockResponse;
  explorerFindBlockByTransaction: BlockResponse;
  explorerFindMention: MentionResponse;
  explorerFindMentionsForAddress: MentionsResponse;
  explorerFindTransactionByHash: TransactionResponse;
  explorerFindTransactionsByAddress: AddressTransactionsResponse;
  explorerFindTransactionsByAddressCount: AddressTransactionsCountResponse;
  explorerFindTransactionsByCluster: ClusterTransactionsResponse;
  explorerFindTransactionsByClusterCount: ClusterTransactionsCountResponse;
  explorerFindTransactionsInBlock: NodeListResponse;
  explorerFindTransactionsInBlockByHash: NodeListResponse;
  explorerFindWalletAddresses: WalletAddressListResponse;
  explorerFindWalletAddressesCount: WalletAddressListCountResponse;
  explorerIncomingAddressListByTransaction: AddressByTransactionListResponse;
  explorerIncomingTransactionsCount: TransactionsCountResponse;
  explorerIncomingTransactionsList: TransactionListResponse;
  explorerMarkup: MarkupResponse;
  explorerOutcomingAddressListByTransaction: AddressByTransactionListResponse;
  explorerOutcomingTransactionsCount: TransactionsCountResponse;
  explorerOutcomingTransactionsList: TransactionListResponse;
  explorerRelationBetweenAddresses: ExplorerRelationBetweenAddressesResp;
  explorerRequestLog: Scalars['Boolean']['output'];
  explorerRisk: RiskResponse;
  explorerTransactionsBetweenAddress: TransactionsBetweenAddressResp;
  explorerTransactionsBetweenAddressAndCluster: TransactionsBetweenAddressAndClusterResponse;
  explorerTransactionsBetweenClusterAndCluster: TransactionsBetweenClusterAndClusterResponse;
  graphByUUID: GraphFindByUuidResponse;
  graphList: GraphListResponse;
  health: Scalars['Boolean']['output'];
  me: User;
  subscribed: Scalars['Boolean']['output'];
  subscriptionList: SubscriptionAddressListResponse;
  user: User;
  userList: UserListResponse;
};

export type QueryBillingKeyListArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryExplorerAddressArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerAddressActivityArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerAddressCategoriesArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerAddressChartArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerAddressTransactionStatsArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerClusterArgs = {
  id: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerClusterDetailsArgs = {
  clusterID: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerClusterForAddressArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerClusterStatsArgs = {
  id: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerFindAddressByHashArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerFindBlockByHashArgs = {
  hash: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerFindBlockByHeightArgs = {
  height: Scalars['Int64']['input'];
  network: Network;
};

export type QueryExplorerFindBlockByTransactionArgs = {
  network: Network;
  txid: Scalars['String']['input'];
};

export type QueryExplorerFindMentionArgs = {
  id: Scalars['ID']['input'];
  network: Network;
};

export type QueryExplorerFindMentionsForAddressArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerFindTransactionByHashArgs = {
  id: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerFindTransactionsByAddressArgs = {
  filter: AddressTransactionsFilter;
  network: Network;
};

export type QueryExplorerFindTransactionsByAddressCountArgs = {
  filter: AddressTransactionsCountFilter;
  network: Network;
};

export type QueryExplorerFindTransactionsByClusterArgs = {
  filter: ClusterTransactionsFilter;
  network: Network;
};

export type QueryExplorerFindTransactionsByClusterCountArgs = {
  filter: ClusterTransactionsCountFilter;
  network: Network;
};

export type QueryExplorerFindTransactionsInBlockArgs = {
  height: Scalars['Int64']['input'];
  network: Network;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryExplorerFindTransactionsInBlockByHashArgs = {
  hash: Scalars['String']['input'];
  network: Network;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryExplorerFindWalletAddressesArgs = {
  filter: WalletAddressesFilter;
  network: Network;
};

export type QueryExplorerFindWalletAddressesCountArgs = {
  filter: WalletAddressesCountFilter;
  network: Network;
};

export type QueryExplorerIncomingAddressListByTransactionArgs = {
  network: Network;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  txid: Scalars['String']['input'];
};

export type QueryExplorerIncomingTransactionsCountArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerIncomingTransactionsListArgs = {
  address: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['Int64']['input']>;
  network: Network;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExplorerMarkupArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerOutcomingAddressListByTransactionArgs = {
  network: Network;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  txid: Scalars['String']['input'];
};

export type QueryExplorerOutcomingTransactionsCountArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerOutcomingTransactionsListArgs = {
  address: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['Int64']['input']>;
  network: Network;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExplorerRelationBetweenAddressesArgs = {
  req: ExplorerRelationBetweenAddressesReq;
};

export type QueryExplorerRequestLogArgs = {
  network: Network;
  request: Scalars['String']['input'];
};

export type QueryExplorerRiskArgs = {
  address: Scalars['String']['input'];
  network: Network;
};

export type QueryExplorerTransactionsBetweenAddressArgs = {
  filter: TransactionsBetweenAddressInput;
  network: Network;
};

export type QueryExplorerTransactionsBetweenAddressAndClusterArgs = {
  filter: TransactionsBetweenAddressAndClusterInput;
  network: Network;
};

export type QueryExplorerTransactionsBetweenClusterAndClusterArgs = {
  filter: TransactionsBetweenClusterAndClusterInput;
  network: Network;
};

export type QueryGraphByUuidArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryGraphListArgs = {
  order?: InputMaybe<OrderDirection>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QuerySubscribedArgs = {
  input: SubscribeInput;
};

export type QuerySubscriptionListArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserListArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  result?: Maybe<Jwt>;
};

export type RegistrationConfirmInput = {
  token: Scalars['String']['input'];
};

export type RegistrationConfirmResponse = {
  __typename?: 'RegistrationConfirmResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  jwt?: Maybe<Jwt>;
};

export type RegistrationInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirm: Scalars['String']['input'];
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  state: Scalars['Boolean']['output'];
};

export type Report = {
  __typename?: 'Report';
  address: Scalars['String']['output'];
  categoryId: Scalars['Int32']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int64']['output'];
  isPaid: Scalars['Boolean']['output'];
  level: Scalars['Int32']['output'];
  mentionId: Scalars['Int64']['output'];
  version: Scalars['Int32']['output'];
};

export type RestoreCheckInput = {
  token: Scalars['String']['input'];
};

export type RestoreConfirmInput = {
  password: Scalars['String']['input'];
  passwordConfirm: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RestoreConfirmResponse = {
  __typename?: 'RestoreConfirmResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  jwt?: Maybe<Jwt>;
};

export type RestoreInput = {
  email: Scalars['String']['input'];
};

export type RestoreResponse = {
  __typename?: 'RestoreResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  state: Scalars['Boolean']['output'];
};

export type Risk = {
  __typename?: 'Risk';
  calculated?: Maybe<CalculatedRisk>;
  cluster?: Maybe<RiskData>;
  reported?: Maybe<RiskData>;
  risk?: Maybe<Scalars['Float']['output']>;
};

export type RiskCategory = {
  __typename?: 'RiskCategory';
  i18n: DirectoryI18n;
  name: Scalars['String']['output'];
  number: Scalars['Int32']['output'];
  value: Scalars['String']['output'];
};

export type RiskData = {
  __typename?: 'RiskData';
  name: Scalars['String']['output'];
  number: Scalars['Int32']['output'];
  risk: Scalars['Float']['output'];
};

export type RiskNodeEntityResponse = {
  __typename?: 'RiskNodeEntityResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  node?: Maybe<Node>;
  risk?: Maybe<CalculatedRisk>;
};

export type RiskResponse = {
  __typename?: 'RiskResponse';
  errors?: Maybe<Scalars['Any']['output']>;
  risk?: Maybe<Risk>;
};

export type SetPermissionsInput = {
  permissions: Array<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type SetPermissionsResponse = {
  __typename?: 'SetPermissionsResponse';
  errors?: Maybe<Scalars['Any']['output']>;
};

export type SubscribeInput = {
  address: Scalars['String']['input'];
  network: Network;
};

export type Subscription = {
  __typename?: 'Subscription';
  health: Scalars['Boolean']['output'];
};

export type SubscriptionAddress = {
  __typename?: 'SubscriptionAddress';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hasResult: Scalars['Boolean']['output'];
  isViewed: Scalars['Boolean']['output'];
  network: Scalars['String']['output'];
};

export type SubscriptionAddressListResponse = {
  __typename?: 'SubscriptionAddressListResponse';
  edge: Array<SubscriptionAddress>;
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum TableType {
  Address = 'ADDRESS',
  Cluster = 'CLUSTER',
  Transaction = 'TRANSACTION',
  TransactionDetail = 'TRANSACTION_DETAIL',
}

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['String']['output'];
  from: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  network: Network;
  timestamp: Scalars['Int64']['output'];
  to: Array<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

export type TransactionListResponse = {
  __typename?: 'TransactionListResponse';
  cursor?: Maybe<Scalars['Int64']['output']>;
  edge: Array<Transaction>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type TransactionResponse = {
  __typename?: 'TransactionResponse';
  transaction?: Maybe<Transaction>;
};

export type TransactionsBetweenAddress = {
  __typename?: 'TransactionsBetweenAddress';
  amount: Scalars['String']['output'];
  direction: Scalars['String']['output'];
  timestamp: Scalars['Int64']['output'];
  token: Scalars['String']['output'];
  txID: Scalars['String']['output'];
};

export type TransactionsBetweenAddressAndClusterInput = {
  direction: AddressTransactionsFilterTxType;
  from: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  timestampFrom?: InputMaybe<Scalars['Int64']['input']>;
  timestampTo?: InputMaybe<Scalars['Int64']['input']>;
  to: Scalars['String']['input'];
};

export type TransactionsBetweenAddressAndClusterResponse = {
  __typename?: 'TransactionsBetweenAddressAndClusterResponse';
  edge: Array<TransactionsBetweenAddress>;
};

export type TransactionsBetweenAddressInput = {
  direction?: InputMaybe<AddressTransactionsFilterTxType>;
  dstAddress: Scalars['String']['input'];
  orderBy?: InputMaybe<OrderDirection>;
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  srcAddress: Scalars['String']['input'];
  timestampFrom?: InputMaybe<Scalars['Int64']['input']>;
  timestampTo?: InputMaybe<Scalars['Int64']['input']>;
};

export type TransactionsBetweenAddressResp = {
  __typename?: 'TransactionsBetweenAddressResp';
  edge: Array<TransactionsBetweenAddress>;
  total?: Maybe<Scalars['Int64']['output']>;
  totalReceive?: Maybe<Scalars['Int64']['output']>;
  totalSent?: Maybe<Scalars['Int64']['output']>;
};

export type TransactionsBetweenClusterAndClusterInput = {
  direction: ClusterClusterTransactionsFilterTxType;
  from: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['String']['input'];
};

export type TransactionsBetweenClusterAndClusterResponse = {
  __typename?: 'TransactionsBetweenClusterAndClusterResponse';
  edge: Array<TransactionsBetweenAddress>;
};

export type TransactionsCountResponse = {
  __typename?: 'TransactionsCountResponse';
  count?: Maybe<Scalars['Int64']['output']>;
  errors?: Maybe<Scalars['Any']['output']>;
};

export type User = {
  __typename?: 'User';
  billing: Billing;
  /** @deprecated Field no longer supported */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Field no longer supported */
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserListResponse = {
  __typename?: 'UserListResponse';
  edge: Array<User>;
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type WalletAddress = {
  __typename?: 'WalletAddress';
  address: Scalars['String']['output'];
  balance: Scalars['String']['output'];
  lastActivity: Scalars['Int64']['output'];
  reported: Scalars['Boolean']['output'];
  totalIn: Scalars['String']['output'];
  totalOut: Scalars['String']['output'];
  transactions: Scalars['String']['output'];
  turnover: Scalars['String']['output'];
};

export type WalletAddressListCountResponse = {
  __typename?: 'WalletAddressListCountResponse';
  /** @deprecated Field no longer supported */
  errors?: Maybe<Scalars['Any']['output']>;
  total?: Maybe<Scalars['String']['output']>;
};

export type WalletAddressListResponse = {
  __typename?: 'WalletAddressListResponse';
  edge: Array<WalletAddress>;
  /** @deprecated Field no longer supported */
  errors?: Maybe<Scalars['Any']['output']>;
};

export type WalletAddressesCountFilter = {
  reportedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  wid: Scalars['String']['input'];
};

export type WalletAddressesFilter = {
  order?: InputMaybe<WalletAddressesOrder>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  reportedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  wid: Scalars['String']['input'];
};

export type WalletAddressesOrder = {
  direction: OrderDirection;
  field: WalletAddressesOrderField;
};

export enum WalletAddressesOrderField {
  LastActivity = 'last_activity',
  Transactions = 'transactions',
}

export type ZoomParamsType = {
  __typename?: 'ZoomParamsType';
  originX?: Maybe<Scalars['Float']['output']>;
  originY?: Maybe<Scalars['Float']['output']>;
  zoom?: Maybe<Scalars['Float']['output']>;
};

export type ZoomParamsTypeInput = {
  originX?: InputMaybe<Scalars['Float']['input']>;
  originY?: InputMaybe<Scalars['Float']['input']>;
  zoom?: InputMaybe<Scalars['Float']['input']>;
};

export type GraphUpdateResponse = {
  __typename?: 'graphUpdateResponse';
  errors?: Maybe<Scalars['Any']['output']>;
};

export type BillingKeyFieldsFragment = {
  __typename?: 'BillingKey';
  createdAt: any;
  key: string;
};

export type BillingFieldsFragment = {
  __typename?: 'Billing';
  address: any;
  transactions: any;
  markup: any;
  analyzerSubscriptionExpireDate?: any | null;
};

export type BillingKeyListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type BillingKeyListQuery = {
  __typename?: 'Query';
  billingKeyList: {
    __typename?: 'BillingKeyResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{ __typename?: 'BillingKey'; createdAt: any; key: string }>;
  };
};

export type BillingAddPacketMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  requests: Scalars['Int64']['input'];
  requestType: BillingRequestType;
}>;

export type BillingAddPacketMutation = {
  __typename?: 'Mutation';
  billingAddPacket: boolean;
};

export type BillingKeyCreateMutationVariables = Exact<{ [key: string]: never }>;

export type BillingKeyCreateMutation = {
  __typename?: 'Mutation';
  billingKeyCreate: { __typename?: 'BillingKey'; createdAt: any; key: string };
};

export type BillingKeyRemoveMutationVariables = Exact<{
  key: Scalars['String']['input'];
}>;

export type BillingKeyRemoveMutation = {
  __typename?: 'Mutation';
  billingKeyRemove: boolean;
};

export type BillingAnalyzerSubscribeMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  expireDate: Scalars['Int64']['input'];
}>;

export type BillingAnalyzerSubscribeMutation = {
  __typename?: 'Mutation';
  billingAnalyzerSubscribe: boolean;
};

export type NodeFieldsFragment = { __typename?: 'Node'; id: string; props?: any | null };

export type MentionFieldsFragment = {
  __typename?: 'Mention';
  id: number;
  description: string;
  createdAt: any;
  label: string;
  link: string;
  address: string;
  reportId?: any | null;
  owner: string;
  isPaid: boolean;
  uuid: any;
  otherAddresses: Array<{
    __typename?: 'OtherAddress';
    network: string;
    address: string;
  }>;
  source: {
    __typename?: 'MentionSource';
    name: string;
    sourceAddress: string;
    categoryNumber: any;
  };
  risk?: { __typename?: 'MentionRisk'; color: string; categoryNumber: any } | null;
};

export type BlockchainBlockFieldsFragment = {
  __typename?: 'BlockchainBlock';
  hash: string;
  height: any;
  timestamp?: any | null;
};

export type FindAddressByHashNodeFieldsFragment = {
  __typename?: 'FindAddressByHashNode';
  totalIn: string;
  totalOut: string;
  total: string;
};

export type CalculatedRiskFieldsFragment = {
  __typename?: 'CalculatedRisk';
  total: string;
  risk: number;
  items: Array<{
    __typename?: 'CalculateItem';
    number: any;
    total: string;
    total_human: string;
    risk: number;
    risk_raw: number;
    percent: number;
    percent_raw: number;
    name: string;
    color: string;
    i18n: { __typename?: 'DirectoryI18n'; en: string; ru: string };
  }>;
};

export type RiskFieldsFragment = {
  __typename?: 'Risk';
  risk?: number | null;
  reported?: { __typename?: 'RiskData'; risk: number; name: string; number: any } | null;
  calculated?: {
    __typename?: 'CalculatedRisk';
    total_human: string;
    total: string;
    risk: number;
    items: Array<{
      __typename?: 'CalculateItem';
      number: any;
      total: string;
      total_human: string;
      risk: number;
      risk_raw: number;
      percent: number;
      percent_raw: number;
      name: string;
      color: string;
      i18n: { __typename?: 'DirectoryI18n'; en: string; ru: string };
    }>;
  } | null;
};

export type ClusterFieldsFragment = {
  __typename?: 'Cluster';
  id: string;
  owner?: string | null;
  riskCategory?: any | null;
  riskVersion: any;
};

export type AddressByTransactionFieldsFragment = {
  __typename?: 'AddressByTransaction';
  network: Network;
  address: string;
  firstAppear: any;
  hexAddress: string;
  summ: string;
  token: string;
  totalIn: string;
  cluster?: {
    __typename?: 'Cluster';
    id: string;
    owner?: string | null;
    riskCategory?: any | null;
    riskVersion: any;
  } | null;
};

export type AddressFieldsFragment = {
  __typename?: 'Address';
  address: string;
  network: Network;
  owner?: string | null;
  cluster?: {
    __typename?: 'Cluster';
    id: string;
    owner?: string | null;
    riskCategory?: any | null;
    riskVersion: any;
  } | null;
};

export type AddressTransactionFieldsFragment = {
  __typename?: 'AddressTransaction';
  timestamp: any;
  txID: string;
  amount: string;
  direction: string;
  token: string;
  recvAddr: Array<{
    __typename?: 'Address';
    address: string;
    network: Network;
    owner?: string | null;
    cluster?: {
      __typename?: 'Cluster';
      id: string;
      owner?: string | null;
      riskCategory?: any | null;
      riskVersion: any;
    } | null;
  }>;
  senderAddr: Array<{
    __typename?: 'Address';
    address: string;
    network: Network;
    owner?: string | null;
    cluster?: {
      __typename?: 'Cluster';
      id: string;
      owner?: string | null;
      riskCategory?: any | null;
      riskVersion: any;
    } | null;
  }>;
};

export type TransactionFieldsFragment = {
  __typename?: 'Transaction';
  network: Network;
  id: string;
  amount: string;
  timestamp: any;
  from: Array<string>;
  to: Array<string>;
  token: string;
};

export type ExplorerFindBlockByHashQueryVariables = Exact<{
  network: Network;
  hash: Scalars['String']['input'];
}>;

export type ExplorerFindBlockByHashQuery = {
  __typename?: 'Query';
  explorerFindBlockByHash: {
    __typename?: 'BlockResponse';
    errors?: any | null;
    node?: {
      __typename?: 'BlockchainBlock';
      hash: string;
      height: any;
      timestamp?: any | null;
    } | null;
  };
};

export type ExplorerFindTransactionsInBlockByHashQueryVariables = Exact<{
  network: Network;
  hash: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ExplorerFindTransactionsInBlockByHashQuery = {
  __typename?: 'Query';
  explorerFindTransactionsInBlockByHash: {
    __typename?: 'NodeListResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{ __typename?: 'Node'; id: string; props?: any | null }>;
  };
};

export type ExplorerFindAddressByHashQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerFindAddressByHashQuery = {
  __typename?: 'Query';
  explorerFindAddressByHash: {
    __typename?: 'FindAddressByHashNodeResponse';
    errors?: any | null;
    node?: {
      __typename?: 'FindAddressByHashNode';
      totalIn: string;
      totalOut: string;
      total: string;
    } | null;
  };
};

export type ExplorerFindTransactionsByAddressQueryVariables = Exact<{
  network: Network;
  filter: AddressTransactionsFilter;
}>;

export type ExplorerFindTransactionsByAddressQuery = {
  __typename?: 'Query';
  explorerFindTransactionsByAddress: {
    __typename?: 'AddressTransactionsResponse';
    errors?: any | null;
    edge?: Array<{
      __typename?: 'AddressTransaction';
      timestamp: any;
      txID: string;
      amount: string;
      direction: string;
      token: string;
      recvAddr: Array<{
        __typename?: 'Address';
        address: string;
        network: Network;
        owner?: string | null;
        cluster?: {
          __typename?: 'Cluster';
          id: string;
          owner?: string | null;
          riskCategory?: any | null;
          riskVersion: any;
        } | null;
      }>;
      senderAddr: Array<{
        __typename?: 'Address';
        address: string;
        network: Network;
        owner?: string | null;
        cluster?: {
          __typename?: 'Cluster';
          id: string;
          owner?: string | null;
          riskCategory?: any | null;
          riskVersion: any;
        } | null;
      }>;
    }> | null;
  };
};

export type ExplorerFindTransactionsByClusterQueryVariables = Exact<{
  network: Network;
  filter: ClusterTransactionsFilter;
}>;

export type ExplorerFindTransactionsByClusterQuery = {
  __typename?: 'Query';
  explorerFindTransactionsByCluster: {
    __typename?: 'ClusterTransactionsResponse';
    errors?: any | null;
    edge?: Array<{
      __typename?: 'AddressTransaction';
      timestamp: any;
      txID: string;
      amount: string;
      direction: string;
      token: string;
      recvAddr: Array<{
        __typename?: 'Address';
        address: string;
        network: Network;
        owner?: string | null;
        cluster?: {
          __typename?: 'Cluster';
          id: string;
          owner?: string | null;
          riskCategory?: any | null;
          riskVersion: any;
        } | null;
      }>;
      senderAddr: Array<{
        __typename?: 'Address';
        address: string;
        network: Network;
        owner?: string | null;
        cluster?: {
          __typename?: 'Cluster';
          id: string;
          owner?: string | null;
          riskCategory?: any | null;
          riskVersion: any;
        } | null;
      }>;
    }> | null;
  };
};

export type ExplorerFindTransactionsByAddressCountQueryVariables = Exact<{
  network: Network;
  filter: AddressTransactionsCountFilter;
}>;

export type ExplorerFindTransactionsByAddressCountQuery = {
  __typename?: 'Query';
  explorerFindTransactionsByAddressCount: {
    __typename?: 'AddressTransactionsCountResponse';
    total?: string | null;
    errors?: any | null;
  };
};

export type ExplorerFindTransactionsByClusterCountQueryVariables = Exact<{
  network: Network;
  filter: ClusterTransactionsCountFilter;
}>;

export type ExplorerFindTransactionsByClusterCountQuery = {
  __typename?: 'Query';
  explorerFindTransactionsByClusterCount: {
    __typename?: 'ClusterTransactionsCountResponse';
    total?: string | null;
    errors?: any | null;
  };
};

export type ExplorerFindMentionsForAddressQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerFindMentionsForAddressQuery = {
  __typename?: 'Query';
  explorerFindMentionsForAddress: {
    __typename?: 'MentionsResponse';
    errors?: any | null;
    edge: Array<{
      __typename?: 'Mention';
      id: number;
      description: string;
      createdAt: any;
      label: string;
      link: string;
      address: string;
      reportId?: any | null;
      owner: string;
      isPaid: boolean;
      uuid: any;
      otherAddresses: Array<{
        __typename?: 'OtherAddress';
        network: string;
        address: string;
      }>;
      source: {
        __typename?: 'MentionSource';
        name: string;
        sourceAddress: string;
        categoryNumber: any;
      };
      risk?: { __typename?: 'MentionRisk'; color: string; categoryNumber: any } | null;
    }>;
  };
};

export type ExplorerRiskQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerRiskQuery = {
  __typename?: 'Query';
  explorerRisk: {
    __typename?: 'RiskResponse';
    errors?: any | null;
    risk?: {
      __typename?: 'Risk';
      risk?: number | null;
      reported?: {
        __typename?: 'RiskData';
        risk: number;
        name: string;
        number: any;
      } | null;
      calculated?: {
        __typename?: 'CalculatedRisk';
        total_human: string;
        total: string;
        risk: number;
        items: Array<{
          __typename?: 'CalculateItem';
          number: any;
          total: string;
          total_human: string;
          risk: number;
          risk_raw: number;
          percent: number;
          percent_raw: number;
          name: string;
          color: string;
          i18n: { __typename?: 'DirectoryI18n'; en: string; ru: string };
        }>;
      } | null;
    } | null;
  };
  explorerAddressCategories: {
    __typename?: 'AddressCategoriesResponse';
    errors?: any | null;
    categories?: Array<{
      __typename?: 'RiskCategory';
      number: any;
      name: string;
      value: string;
    }> | null;
  };
};

export type ExplorerFindTransactionByHashQueryVariables = Exact<{
  network: Network;
  id: Scalars['String']['input'];
}>;

export type ExplorerFindTransactionByHashQuery = {
  __typename?: 'Query';
  explorerFindTransactionByHash: {
    __typename?: 'TransactionResponse';
    transaction?: {
      __typename?: 'Transaction';
      network: Network;
      id: string;
      amount: string;
      timestamp: any;
      from: Array<string>;
      to: Array<string>;
      token: string;
    } | null;
  };
};

export type ExplorerIncomingAddressListByTransactionQueryVariables = Exact<{
  network: Network;
  txid: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ExplorerIncomingAddressListByTransactionQuery = {
  __typename?: 'Query';
  explorerIncomingAddressListByTransaction: {
    __typename?: 'AddressByTransactionListResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{
      __typename?: 'AddressByTransaction';
      network: Network;
      address: string;
      firstAppear: any;
      hexAddress: string;
      summ: string;
      token: string;
      totalIn: string;
      cluster?: {
        __typename?: 'Cluster';
        id: string;
        owner?: string | null;
        riskCategory?: any | null;
        riskVersion: any;
      } | null;
    }>;
  };
};

export type ExplorerOutcomingAddressListByTransactionQueryVariables = Exact<{
  network: Network;
  txid: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ExplorerOutcomingAddressListByTransactionQuery = {
  __typename?: 'Query';
  explorerOutcomingAddressListByTransaction: {
    __typename?: 'AddressByTransactionListResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{
      __typename?: 'AddressByTransaction';
      network: Network;
      address: string;
      firstAppear: any;
      hexAddress: string;
      summ: string;
      token: string;
      totalIn: string;
      cluster?: {
        __typename?: 'Cluster';
        id: string;
        owner?: string | null;
        riskCategory?: any | null;
        riskVersion: any;
      } | null;
    }>;
  };
};

export type ExplorerFindBlockByTransactionQueryVariables = Exact<{
  network: Network;
  txid: Scalars['String']['input'];
}>;

export type ExplorerFindBlockByTransactionQuery = {
  __typename?: 'Query';
  explorerFindBlockByTransaction: {
    __typename?: 'BlockResponse';
    errors?: any | null;
    node?: {
      __typename?: 'BlockchainBlock';
      hash: string;
      height: any;
      timestamp?: any | null;
    } | null;
  };
};

export type ExplorerFindBlockByNumberQueryVariables = Exact<{
  network: Network;
  height: Scalars['Int64']['input'];
}>;

export type ExplorerFindBlockByNumberQuery = {
  __typename?: 'Query';
  explorerFindBlockByHeight: {
    __typename?: 'BlockResponse';
    errors?: any | null;
    node?: {
      __typename?: 'BlockchainBlock';
      hash: string;
      height: any;
      timestamp?: any | null;
    } | null;
  };
};

export type ExplorerFindTransactionsInBlockQueryVariables = Exact<{
  network: Network;
  height: Scalars['Int64']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ExplorerFindTransactionsInBlockQuery = {
  __typename?: 'Query';
  explorerFindTransactionsInBlock: {
    __typename?: 'NodeListResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{ __typename?: 'Node'; id: string; props?: any | null }>;
  };
};

export type ExplorerFindTransactionsWithBlockQueryVariables = Exact<{
  network: Network;
  height: Scalars['Int64']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type ExplorerFindTransactionsWithBlockQuery = {
  __typename?: 'Query';
  explorerFindBlockByHeight: {
    __typename?: 'BlockResponse';
    errors?: any | null;
    node?: {
      __typename?: 'BlockchainBlock';
      hash: string;
      height: any;
      timestamp?: any | null;
    } | null;
  };
  explorerFindTransactionsInBlock: {
    __typename?: 'NodeListResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{ __typename?: 'Node'; id: string; props?: any | null }>;
  };
};

export type ExplorerClusterQueryVariables = Exact<{
  network: Network;
  id: Scalars['String']['input'];
}>;

export type ExplorerClusterQuery = {
  __typename?: 'Query';
  explorerCluster: {
    __typename?: 'ClusterResponse';
    cluster?: {
      __typename?: 'Cluster';
      id: string;
      owner?: string | null;
      riskCategory?: any | null;
      riskVersion: any;
    } | null;
  };
  explorerClusterStats: {
    __typename?: 'ClusterStatsResponse';
    stats?: {
      __typename?: 'ClusterStats';
      addressCount: string;
      firstTx: any;
      lastTx: any;
      totalAmount: string;
      totalCount: string;
      sentAmount: string;
      sentCount: string;
      receivedAmount: string;
      receivedCount: string;
    } | null;
  };
};

export type ExplorerFindWalletAddressesQueryVariables = Exact<{
  network: Network;
  filter: WalletAddressesFilter;
}>;

export type ExplorerFindWalletAddressesQuery = {
  __typename?: 'Query';
  explorerFindWalletAddresses: {
    __typename?: 'WalletAddressListResponse';
    errors?: any | null;
    edge: Array<{
      __typename?: 'WalletAddress';
      address: string;
      lastActivity: any;
      reported: boolean;
      balance: string;
      turnover: string;
      totalIn: string;
      totalOut: string;
      transactions: string;
    }>;
  };
};

export type ExplorerFindWalletAddressesCountQueryVariables = Exact<{
  network: Network;
  filter: WalletAddressesCountFilter;
}>;

export type ExplorerFindWalletAddressesCountQuery = {
  __typename?: 'Query';
  explorerFindWalletAddressesCount: {
    __typename?: 'WalletAddressListCountResponse';
    total?: string | null;
  };
};

export type ExplorerClusterForAddressQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerClusterForAddressQuery = {
  __typename?: 'Query';
  explorerClusterForAddress: {
    __typename?: 'ClusterResponse';
    cluster?: {
      __typename?: 'Cluster';
      id: string;
      owner?: string | null;
      riskCategory?: any | null;
      riskVersion: any;
    } | null;
  };
};

export type ExplorerAddressQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerAddressQuery = {
  __typename?: 'Query';
  explorerAddress: {
    __typename?: 'Address';
    network: Network;
    address: string;
    owner?: string | null;
  };
};

export type ExplorerAddressActivityQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerAddressActivityQuery = {
  __typename?: 'Query';
  explorerAddressActivity: {
    __typename?: 'AddressActivityResponse';
    errors?: any | null;
    activity?: { __typename?: 'AddressActivity'; first: any; last: any } | null;
  };
};

export type ExplorerAddressTransactionStatsQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerAddressTransactionStatsQuery = {
  __typename?: 'Query';
  explorerAddressTransactionStats: {
    __typename?: 'AddressTransactionStatsResponse';
    errors?: any | null;
    stats?: {
      __typename?: 'AddressTransactionStats';
      total: string;
      sent: string;
      received: string;
    } | null;
  };
};

export type ExplorerAddressCategoriesQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerAddressCategoriesQuery = {
  __typename?: 'Query';
  explorerAddressCategories: {
    __typename?: 'AddressCategoriesResponse';
    errors?: any | null;
    categories?: Array<{
      __typename?: 'RiskCategory';
      number: any;
      name: string;
      value: string;
    }> | null;
  };
};

export type ExplorerAddressChartQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerAddressChartQuery = {
  __typename?: 'Query';
  explorerAddressChart: {
    __typename?: 'AddressChartResponse';
    errors?: any | null;
    charts: Array<{
      __typename?: 'AddressChart';
      resdrt?: string | null;
      rescat?: any | null;
      address?: string | null;
      wid?: string | null;
      received?: string | null;
      owner?: string | null;
      reported?: boolean | null;
      percent?: string | null;
    }>;
  };
};

export type ExplorerMarkupQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type ExplorerMarkupQuery = {
  __typename?: 'Query';
  explorerMarkup: {
    __typename?: 'MarkupResponse';
    errors?: any | null;
    screenshots?: Array<string> | null;
    risk?: { __typename?: 'Risk'; risk?: number | null } | null;
    report?: {
      __typename?: 'Report';
      id: any;
      address: string;
      version: any;
      categoryId: any;
      level: any;
      createdAt: any;
      isPaid: boolean;
      mentionId: any;
    } | null;
    mention?: {
      __typename?: 'Mention';
      description: string;
      domain: string;
      link: string;
      label: string;
      address: string;
      owner: string;
      reportId?: any | null;
      createdAt: any;
      isPaid: boolean;
      risk?: { __typename?: 'MentionRisk'; categoryNumber: any; color: string } | null;
      otherAddresses: Array<{
        __typename?: 'OtherAddress';
        network: string;
        address: string;
      }>;
      source: {
        __typename?: 'MentionSource';
        id: any;
        name: string;
        description: string;
        sourceAddress: string;
        categoryNumber: any;
        version: any;
      };
    } | null;
  };
};

export type ExplorerFindMentionQueryVariables = Exact<{
  network: Network;
  id: Scalars['ID']['input'];
}>;

export type ExplorerFindMentionQuery = {
  __typename?: 'Query';
  explorerFindMention: {
    __typename?: 'MentionResponse';
    screenshots?: Array<string> | null;
    mention?: {
      __typename?: 'Mention';
      id: number;
      description: string;
      createdAt: any;
      label: string;
      link: string;
      address: string;
      reportId?: any | null;
      owner: string;
      isPaid: boolean;
      uuid: any;
      otherAddresses: Array<{
        __typename?: 'OtherAddress';
        network: string;
        address: string;
      }>;
      source: {
        __typename?: 'MentionSource';
        name: string;
        sourceAddress: string;
        categoryNumber: any;
      };
      risk?: { __typename?: 'MentionRisk'; color: string; categoryNumber: any } | null;
    } | null;
  };
};

export type MentionSourceListQueryVariables = Exact<{ [key: string]: never }>;

export type MentionSourceListQuery = {
  __typename?: 'Query';
  mentionSourceList: {
    __typename?: 'MentionSourceListResp';
    mentionSources: Array<{
      __typename?: 'MentionSource';
      id: any;
      name: string;
      description: string;
      sourceAddress: string;
      categoryNumber: any;
      version: any;
    }>;
  };
};

export type ExplorerMarkupCreateMutationVariables = Exact<{
  req: ExplorerMarkupCreateInput;
}>;

export type ExplorerMarkupCreateMutation = {
  __typename?: 'Mutation';
  explorerMarkupCreate: {
    __typename?: 'MentionResponse';
    screenshots?: Array<string> | null;
    mention?: {
      __typename?: 'Mention';
      id: number;
      description: string;
      createdAt: any;
      label: string;
      link: string;
      address: string;
      reportId?: any | null;
      owner: string;
      isPaid: boolean;
      uuid: any;
      otherAddresses: Array<{
        __typename?: 'OtherAddress';
        network: string;
        address: string;
      }>;
      source: {
        __typename?: 'MentionSource';
        name: string;
        sourceAddress: string;
        categoryNumber: any;
      };
      risk?: { __typename?: 'MentionRisk'; color: string; categoryNumber: any } | null;
    } | null;
  };
};

export type UserFieldsFragment = {
  __typename?: 'User';
  id: number;
  name: string;
  email: string;
  isActive: boolean;
  permissions: Array<string>;
  createdAt: any;
  updatedAt: any;
  billing: {
    __typename?: 'Billing';
    address: any;
    transactions: any;
    markup: any;
    analyzerSubscriptionExpireDate?: any | null;
  };
};

export type JwtFieldsFragment = {
  __typename?: 'Jwt';
  id: number;
  accessToken: string;
  refreshToken: string;
  permissions: Array<string>;
};

export type TransactionBetweenFieldsFragment = {
  __typename?: 'TransactionsBetweenAddress';
  timestamp: any;
  txID: string;
  amount: string;
  token: string;
  direction: string;
};

export type GraphFieldsFragment = {
  __typename?: 'GraphData';
  uuid: any;
  createdAt: any;
  updatedAt: any;
  data: {
    __typename?: 'GraphSaveData';
    meta: {
      __typename?: 'GraphMeta';
      name: string;
      network: Network;
      canvas?: {
        __typename?: 'ZoomParamsType';
        originX?: number | null;
        originY?: number | null;
        zoom?: number | null;
      } | null;
      current: { __typename?: 'GraphHashes'; hash: string; type: TableType };
    };
    graph: {
      __typename?: 'Graph';
      nodes: Array<{
        __typename?: 'GraphNode';
        hash: string;
        total: number;
        totalIn: number;
        totalOut: number;
        balance: number;
        hasMention: boolean;
        category: string;
        risk?: number | null;
        tableHash: string;
        reportedCategory?: string | null;
        cluster?: {
          __typename?: 'Cluster';
          id: string;
          owner?: string | null;
          riskCategory?: any | null;
          riskVersion: any;
        } | null;
        coords?: { __typename?: 'NodeCoords'; x: number; y: number } | null;
        checked: Array<{
          __typename?: 'AddressCheckedType';
          txid: string;
          type: AddressTransactionsFilterTxType;
          source: string;
          target: string;
        }>;
        annotations: Array<{
          __typename?: 'Annotation';
          x: number;
          y: number;
          text: string;
        }>;
      }>;
      links: Array<{
        __typename?: 'GraphLink';
        target: string;
        source: string;
        annotation?: string | null;
        color?: string | null;
        amount: Array<{
          __typename?: 'AmountType';
          type: AddressTransactionsFilterTxType;
          value: number;
          txid: string;
        }>;
      }>;
    };
  };
};

export type GraphTransactionsBetweenAddressQueryVariables = Exact<{
  network: Network;
  filter: TransactionsBetweenAddressInput;
}>;

export type GraphTransactionsBetweenAddressQuery = {
  __typename?: 'Query';
  explorerTransactionsBetweenAddress: {
    __typename?: 'TransactionsBetweenAddressResp';
    total?: any | null;
    totalReceive?: any | null;
    totalSent?: any | null;
    edge: Array<{
      __typename?: 'TransactionsBetweenAddress';
      timestamp: any;
      txID: string;
      amount: string;
      token: string;
      direction: string;
    }>;
  };
};

export type GraphTransactionsBetweenAddressAndClusterQueryVariables = Exact<{
  network: Network;
  filter: TransactionsBetweenAddressAndClusterInput;
}>;

export type GraphTransactionsBetweenAddressAndClusterQuery = {
  __typename?: 'Query';
  explorerTransactionsBetweenAddressAndCluster: {
    __typename?: 'TransactionsBetweenAddressAndClusterResponse';
    edge: Array<{
      __typename?: 'TransactionsBetweenAddress';
      timestamp: any;
      txID: string;
      amount: string;
      token: string;
      direction: string;
    }>;
  };
};

export type GraphTransactionsBetweenClusterAndClusterQueryVariables = Exact<{
  network: Network;
  filter: TransactionsBetweenClusterAndClusterInput;
}>;

export type GraphTransactionsBetweenClusterAndClusterQuery = {
  __typename?: 'Query';
  explorerTransactionsBetweenClusterAndCluster: {
    __typename?: 'TransactionsBetweenClusterAndClusterResponse';
    edge: Array<{
      __typename?: 'TransactionsBetweenAddress';
      timestamp: any;
      txID: string;
      amount: string;
      token: string;
      direction: string;
    }>;
  };
};

export type GraphRelationBetweenAddressesQueryVariables = Exact<{
  params: ExplorerRelationBetweenAddressesReq;
}>;

export type GraphRelationBetweenAddressesQuery = {
  __typename?: 'Query';
  explorerRelationBetweenAddresses: {
    __typename?: 'ExplorerRelationBetweenAddressesResp';
    relations: Array<{
      __typename?: 'ExplorerRelation';
      timestamp: any;
      transactionId: string;
      token: string;
      amount: string;
      address: string;
      direction: string;
    }>;
  };
};

export type GraphStatsAddressByHashQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type GraphStatsAddressByHashQuery = {
  __typename?: 'Query';
  explorerFindAddressByHash: {
    __typename?: 'FindAddressByHashNodeResponse';
    errors?: any | null;
    node?: {
      __typename?: 'FindAddressByHashNode';
      totalIn: string;
      totalOut: string;
      total: string;
    } | null;
  };
  explorerAddressTransactionStats: {
    __typename?: 'AddressTransactionStatsResponse';
    errors?: any | null;
    stats?: {
      __typename?: 'AddressTransactionStats';
      total: string;
      sent: string;
      received: string;
    } | null;
  };
};

export type GraphRiskAddressByHashQueryVariables = Exact<{
  network: Network;
  address: Scalars['String']['input'];
}>;

export type GraphRiskAddressByHashQuery = {
  __typename?: 'Query';
  explorerRisk: {
    __typename?: 'RiskResponse';
    errors?: any | null;
    risk?: {
      __typename?: 'Risk';
      risk?: number | null;
      reported?: {
        __typename?: 'RiskData';
        risk: number;
        name: string;
        number: any;
      } | null;
      calculated?: {
        __typename?: 'CalculatedRisk';
        total_human: string;
        total: string;
        risk: number;
        items: Array<{
          __typename?: 'CalculateItem';
          number: any;
          total: string;
          total_human: string;
          risk: number;
          risk_raw: number;
          percent: number;
          percent_raw: number;
          name: string;
          color: string;
          i18n: { __typename?: 'DirectoryI18n'; en: string; ru: string };
        }>;
      } | null;
    } | null;
  };
};

export type GraphClusterQueryVariables = Exact<{
  network: Network;
  id: Scalars['String']['input'];
}>;

export type GraphClusterQuery = {
  __typename?: 'Query';
  explorerCluster: {
    __typename?: 'ClusterResponse';
    cluster?: {
      __typename?: 'Cluster';
      id: string;
      owner?: string | null;
      riskCategory?: any | null;
      riskVersion: any;
    } | null;
  };
  explorerClusterStats: {
    __typename?: 'ClusterStatsResponse';
    stats?: {
      __typename?: 'ClusterStats';
      addressCount: string;
      firstTx: any;
      lastTx: any;
      totalAmount: string;
      totalCount: string;
      sentAmount: string;
      sentCount: string;
      receivedAmount: string;
      receivedCount: string;
    } | null;
  };
};

export type GraphListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  order?: InputMaybe<OrderDirection>;
}>;

export type GraphListQuery = {
  __typename?: 'Query';
  graphList: {
    __typename?: 'GraphListResponse';
    errors?: any | null;
    total?: number | null;
    edge: Array<{
      __typename?: 'GraphData';
      updatedAt: any;
      uuid: any;
      data: {
        __typename?: 'GraphSaveData';
        meta: { __typename?: 'GraphMeta'; name: string; network: Network };
      };
    }>;
  };
};

export type GraphByUuidQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type GraphByUuidQuery = {
  __typename?: 'Query';
  graphByUUID: {
    __typename?: 'GraphFindByUUIDResponse';
    errors?: any | null;
    graph?: {
      __typename?: 'GraphData';
      uuid: any;
      createdAt: any;
      updatedAt: any;
      data: {
        __typename?: 'GraphSaveData';
        meta: {
          __typename?: 'GraphMeta';
          name: string;
          network: Network;
          canvas?: {
            __typename?: 'ZoomParamsType';
            originX?: number | null;
            originY?: number | null;
            zoom?: number | null;
          } | null;
          current: { __typename?: 'GraphHashes'; hash: string; type: TableType };
        };
        graph: {
          __typename?: 'Graph';
          nodes: Array<{
            __typename?: 'GraphNode';
            hash: string;
            total: number;
            totalIn: number;
            totalOut: number;
            balance: number;
            hasMention: boolean;
            category: string;
            risk?: number | null;
            tableHash: string;
            reportedCategory?: string | null;
            cluster?: {
              __typename?: 'Cluster';
              id: string;
              owner?: string | null;
              riskCategory?: any | null;
              riskVersion: any;
            } | null;
            coords?: { __typename?: 'NodeCoords'; x: number; y: number } | null;
            checked: Array<{
              __typename?: 'AddressCheckedType';
              txid: string;
              type: AddressTransactionsFilterTxType;
              source: string;
              target: string;
            }>;
            annotations: Array<{
              __typename?: 'Annotation';
              x: number;
              y: number;
              text: string;
            }>;
          }>;
          links: Array<{
            __typename?: 'GraphLink';
            target: string;
            source: string;
            annotation?: string | null;
            color?: string | null;
            amount: Array<{
              __typename?: 'AmountType';
              type: AddressTransactionsFilterTxType;
              value: number;
              txid: string;
            }>;
          }>;
        };
      };
    } | null;
  };
};

export type GraphUpdateMutationVariables = Exact<{
  input: GraphDataInput;
}>;

export type GraphUpdateMutation = {
  __typename?: 'Mutation';
  graphUpdate: { __typename?: 'graphUpdateResponse'; errors?: any | null };
};

export type GraphSaveMutationVariables = Exact<{
  input: GraphDataInput;
}>;

export type GraphSaveMutation = {
  __typename?: 'Mutation';
  graphSave: { __typename?: 'GraphSaveResponse'; errors?: any | null; uuid?: any | null };
};

export type GraphDeleteMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type GraphDeleteMutation = {
  __typename?: 'Mutation';
  graphDelete: { __typename?: 'GraphDeleteResponse'; errors?: any | null };
};

export type GraphRenameMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;

export type GraphRenameMutation = {
  __typename?: 'Mutation';
  graphMetaNameUpdate: {
    __typename?: 'GraphMetaNameUpdateResponse';
    errors?: any | null;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginResponse';
    errors?: any | null;
    jwt?: {
      __typename?: 'Jwt';
      id: number;
      accessToken: string;
      refreshToken: string;
      permissions: Array<string>;
    } | null;
  };
};

export type ExplorerMentionRemoveMutationVariables = Exact<{
  req: MentionRemoveInput;
}>;

export type ExplorerMentionRemoveMutation = {
  __typename?: 'Mutation';
  explorerMentionRemove: boolean;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    isActive: boolean;
    createdAt: any;
    updatedAt: any;
    billing: {
      __typename?: 'Billing';
      address: any;
      transactions: any;
      markup: any;
      analyzerSubscriptionExpireDate?: any | null;
    };
  };
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: {
    __typename?: 'RefreshTokenResponse';
    errors?: any | null;
    result?: {
      __typename?: 'Jwt';
      id: number;
      accessToken: string;
      refreshToken: string;
      permissions: Array<string>;
    } | null;
  };
};

export type RegistrationMutationVariables = Exact<{
  input: RegistrationInput;
}>;

export type RegistrationMutation = {
  __typename?: 'Mutation';
  registration: {
    __typename?: 'RegistrationResponse';
    state: boolean;
    errors?: any | null;
  };
};

export type RegistrationConfirmMutationVariables = Exact<{
  input: RegistrationConfirmInput;
}>;

export type RegistrationConfirmMutation = {
  __typename?: 'Mutation';
  registrationConfirm: {
    __typename?: 'RegistrationConfirmResponse';
    errors?: any | null;
    jwt?: {
      __typename?: 'Jwt';
      id: number;
      accessToken: string;
      refreshToken: string;
      permissions: Array<string>;
    } | null;
  };
};

export type RestoreMutationVariables = Exact<{
  input: RestoreInput;
}>;

export type RestoreMutation = {
  __typename?: 'Mutation';
  restore: { __typename?: 'RestoreResponse'; state: boolean; errors?: any | null };
};

export type RestoreCheckMutationVariables = Exact<{
  input: RestoreCheckInput;
}>;

export type RestoreCheckMutation = {
  __typename?: 'Mutation';
  restoreCheck: { __typename?: 'RestoreResponse'; state: boolean; errors?: any | null };
};

export type RestoreConfirmMutationVariables = Exact<{
  input: RestoreConfirmInput;
}>;

export type RestoreConfirmMutation = {
  __typename?: 'Mutation';
  restoreConfirm: {
    __typename?: 'RestoreConfirmResponse';
    errors?: any | null;
    jwt?: {
      __typename?: 'Jwt';
      id: number;
      accessToken: string;
      refreshToken: string;
      permissions: Array<string>;
    } | null;
  };
};

export type SubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribeMutation = { __typename?: 'Mutation'; subscribe: boolean };

export type UnsubscribeMutationVariables = Exact<{
  input: SubscribeInput;
}>;

export type UnsubscribeMutation = { __typename?: 'Mutation'; unsubscribe: boolean };

export type SubscribedQueryVariables = Exact<{
  input: SubscribeInput;
}>;

export type SubscribedQuery = { __typename?: 'Query'; subscribed: boolean };

export type SubscriptionListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type SubscriptionListQuery = {
  __typename?: 'Query';
  subscriptionList: {
    __typename?: 'SubscriptionAddressListResponse';
    errors?: any | null;
    total?: number | null;
    edge: Array<{
      __typename?: 'SubscriptionAddress';
      network: string;
      address: string;
      createdAt: any;
      isViewed: boolean;
      hasResult: boolean;
    }>;
  };
};

export type UserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    isActive: boolean;
    permissions: Array<string>;
    createdAt: any;
    updatedAt: any;
    billing: {
      __typename?: 'Billing';
      address: any;
      transactions: any;
      markup: any;
      analyzerSubscriptionExpireDate?: any | null;
    };
  };
};

export type UserListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type UserListQuery = {
  __typename?: 'Query';
  userList: {
    __typename?: 'UserListResponse';
    total?: number | null;
    errors?: any | null;
    edge: Array<{
      __typename?: 'User';
      id: number;
      name: string;
      email: string;
      isActive: boolean;
      permissions: Array<string>;
      createdAt: any;
      updatedAt: any;
      billing: {
        __typename?: 'Billing';
        address: any;
        transactions: any;
        markup: any;
        analyzerSubscriptionExpireDate?: any | null;
      };
    }>;
  };
};

export type ActivateMutationVariables = Exact<{
  input: ActivateInput;
}>;

export type ActivateMutation = {
  __typename?: 'Mutation';
  activate: { __typename?: 'ActivateResponse'; state: boolean; errors?: any | null };
};

export type CreateMutationVariables = Exact<{
  input: CreateInput;
}>;

export type CreateMutation = {
  __typename?: 'Mutation';
  create: {
    __typename?: 'CreateResponse';
    email: string;
    permissions: Array<string>;
    password: string;
    errors?: any | null;
  };
};

export type GeneratePasswordMutationVariables = Exact<{
  input: GeneratePasswordInput;
}>;

export type GeneratePasswordMutation = {
  __typename?: 'Mutation';
  generatePassword: {
    __typename?: 'GeneratePasswordResponse';
    password: string;
    errors?: any | null;
  };
};

export type SetUserPermissionsMutationVariables = Exact<{
  input: SetPermissionsInput;
}>;

export type SetUserPermissionsMutation = {
  __typename?: 'Mutation';
  setUserPermissions: { __typename?: 'SetPermissionsResponse'; errors?: any | null };
};

export type DeleteMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMutation = {
  __typename?: 'Mutation';
  delete: { __typename?: 'DeleteResponse'; state: boolean; errors?: any | null };
};

export const BillingKeyFieldsFragmentDoc = gql`
  fragment BillingKeyFields on BillingKey {
    createdAt
    key
  }
`;
export const NodeFieldsFragmentDoc = gql`
  fragment NodeFields on Node {
    id
    props
  }
`;
export const MentionFieldsFragmentDoc = gql`
  fragment MentionFields on Mention {
    id
    description
    createdAt
    label
    link
    address
    reportId
    owner
    otherAddresses {
      network
      address
    }
    owner
    source {
      name
      sourceAddress
      categoryNumber
    }
    risk {
      color
      categoryNumber
    }
    isPaid
    uuid
  }
`;
export const BlockchainBlockFieldsFragmentDoc = gql`
  fragment BlockchainBlockFields on BlockchainBlock {
    hash
    height
    timestamp
  }
`;
export const FindAddressByHashNodeFieldsFragmentDoc = gql`
  fragment FindAddressByHashNodeFields on FindAddressByHashNode {
    totalIn
    totalOut
    total
  }
`;
export const CalculatedRiskFieldsFragmentDoc = gql`
  fragment CalculatedRiskFields on CalculatedRisk {
    total
    risk
    items {
      number
      total
      total_human
      risk
      risk_raw
      percent
      percent_raw
      total
      name
      i18n {
        en
        ru
      }
      color
    }
  }
`;
export const RiskFieldsFragmentDoc = gql`
  fragment RiskFields on Risk {
    risk
    reported {
      risk
      name
      number
    }
    calculated {
      total_human
      ...CalculatedRiskFields
    }
  }
  ${CalculatedRiskFieldsFragmentDoc}
`;
export const ClusterFieldsFragmentDoc = gql`
  fragment ClusterFields on Cluster {
    id
    owner
    riskCategory
    riskVersion
  }
`;
export const AddressByTransactionFieldsFragmentDoc = gql`
  fragment AddressByTransactionFields on AddressByTransaction {
    network
    address
    firstAppear
    hexAddress
    summ
    token
    totalIn
    cluster {
      ...ClusterFields
    }
  }
  ${ClusterFieldsFragmentDoc}
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    address
    network
    owner
    cluster {
      ...ClusterFields
    }
  }
  ${ClusterFieldsFragmentDoc}
`;
export const AddressTransactionFieldsFragmentDoc = gql`
  fragment AddressTransactionFields on AddressTransaction {
    timestamp
    txID
    amount
    direction
    recvAddr {
      ...AddressFields
    }
    senderAddr {
      ...AddressFields
    }
    token
  }
  ${AddressFieldsFragmentDoc}
`;
export const TransactionFieldsFragmentDoc = gql`
  fragment TransactionFields on Transaction {
    network
    id
    amount
    timestamp
    from
    to
    token
  }
`;
export const BillingFieldsFragmentDoc = gql`
  fragment BillingFields on Billing {
    address
    transactions
    markup
    analyzerSubscriptionExpireDate
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    name
    email
    isActive
    permissions
    createdAt
    updatedAt
    billing {
      ...BillingFields
    }
  }
  ${BillingFieldsFragmentDoc}
`;
export const JwtFieldsFragmentDoc = gql`
  fragment JwtFields on Jwt {
    id
    accessToken
    refreshToken
    permissions
  }
`;
export const TransactionBetweenFieldsFragmentDoc = gql`
  fragment TransactionBetweenFields on TransactionsBetweenAddress {
    timestamp
    txID
    amount
    token
    direction
  }
`;
export const GraphFieldsFragmentDoc = gql`
  fragment GraphFields on GraphData {
    uuid
    createdAt
    updatedAt
    data {
      meta {
        name
        canvas {
          originX
          originY
          zoom
        }
        current {
          hash
          type
        }
        network
      }
      graph {
        nodes {
          hash
          total
          totalIn
          totalOut
          balance
          hasMention
          category
          risk
          tableHash
          reportedCategory
          cluster {
            ...ClusterFields
          }
          coords {
            x
            y
          }
          checked {
            txid
            type
            source
            target
          }
          annotations {
            x
            y
            text
          }
        }
        links {
          target
          source
          annotation
          color
          amount {
            type
            value
            txid
          }
        }
      }
    }
  }
  ${ClusterFieldsFragmentDoc}
`;
export const BillingKeyListDocument = gql`
  query billingKeyList($page: Int!, $pageSize: Int!) {
    billingKeyList(page: $page, pageSize: $pageSize) {
      total
      edge {
        ...BillingKeyFields
      }
      errors
    }
  }
  ${BillingKeyFieldsFragmentDoc}
`;

/**
 * __useBillingKeyListQuery__
 *
 * To run a query within a React component, call `useBillingKeyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingKeyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingKeyListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useBillingKeyListQuery(
  baseOptions: Apollo.QueryHookOptions<BillingKeyListQuery, BillingKeyListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingKeyListQuery, BillingKeyListQueryVariables>(
    BillingKeyListDocument,
    options,
  );
}
export function useBillingKeyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingKeyListQuery,
    BillingKeyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingKeyListQuery, BillingKeyListQueryVariables>(
    BillingKeyListDocument,
    options,
  );
}
export function useBillingKeyListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BillingKeyListQuery,
    BillingKeyListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BillingKeyListQuery, BillingKeyListQueryVariables>(
    BillingKeyListDocument,
    options,
  );
}
export type BillingKeyListQueryHookResult = ReturnType<typeof useBillingKeyListQuery>;
export type BillingKeyListLazyQueryHookResult = ReturnType<
  typeof useBillingKeyListLazyQuery
>;
export type BillingKeyListSuspenseQueryHookResult = ReturnType<
  typeof useBillingKeyListSuspenseQuery
>;
export type BillingKeyListQueryResult = Apollo.QueryResult<
  BillingKeyListQuery,
  BillingKeyListQueryVariables
>;
export const BillingAddPacketDocument = gql`
  mutation billingAddPacket(
    $userId: ID!
    $requests: Int64!
    $requestType: BillingRequestType!
  ) {
    billingAddPacket(userId: $userId, requests: $requests, requestType: $requestType)
  }
`;
export type BillingAddPacketMutationFn = Apollo.MutationFunction<
  BillingAddPacketMutation,
  BillingAddPacketMutationVariables
>;

/**
 * __useBillingAddPacketMutation__
 *
 * To run a mutation, you first call `useBillingAddPacketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingAddPacketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingAddPacketMutation, { data, loading, error }] = useBillingAddPacketMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      requests: // value for 'requests'
 *      requestType: // value for 'requestType'
 *   },
 * });
 */
export function useBillingAddPacketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingAddPacketMutation,
    BillingAddPacketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingAddPacketMutation, BillingAddPacketMutationVariables>(
    BillingAddPacketDocument,
    options,
  );
}
export type BillingAddPacketMutationHookResult = ReturnType<
  typeof useBillingAddPacketMutation
>;
export type BillingAddPacketMutationResult =
  Apollo.MutationResult<BillingAddPacketMutation>;
export type BillingAddPacketMutationOptions = Apollo.BaseMutationOptions<
  BillingAddPacketMutation,
  BillingAddPacketMutationVariables
>;
export const BillingKeyCreateDocument = gql`
  mutation billingKeyCreate {
    billingKeyCreate {
      ...BillingKeyFields
    }
  }
  ${BillingKeyFieldsFragmentDoc}
`;
export type BillingKeyCreateMutationFn = Apollo.MutationFunction<
  BillingKeyCreateMutation,
  BillingKeyCreateMutationVariables
>;

/**
 * __useBillingKeyCreateMutation__
 *
 * To run a mutation, you first call `useBillingKeyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingKeyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingKeyCreateMutation, { data, loading, error }] = useBillingKeyCreateMutation({
 *   variables: {
 *   },
 * });
 */
export function useBillingKeyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingKeyCreateMutation,
    BillingKeyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingKeyCreateMutation, BillingKeyCreateMutationVariables>(
    BillingKeyCreateDocument,
    options,
  );
}
export type BillingKeyCreateMutationHookResult = ReturnType<
  typeof useBillingKeyCreateMutation
>;
export type BillingKeyCreateMutationResult =
  Apollo.MutationResult<BillingKeyCreateMutation>;
export type BillingKeyCreateMutationOptions = Apollo.BaseMutationOptions<
  BillingKeyCreateMutation,
  BillingKeyCreateMutationVariables
>;
export const BillingKeyRemoveDocument = gql`
  mutation billingKeyRemove($key: String!) {
    billingKeyRemove(key: $key)
  }
`;
export type BillingKeyRemoveMutationFn = Apollo.MutationFunction<
  BillingKeyRemoveMutation,
  BillingKeyRemoveMutationVariables
>;

/**
 * __useBillingKeyRemoveMutation__
 *
 * To run a mutation, you first call `useBillingKeyRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingKeyRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingKeyRemoveMutation, { data, loading, error }] = useBillingKeyRemoveMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useBillingKeyRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingKeyRemoveMutation,
    BillingKeyRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BillingKeyRemoveMutation, BillingKeyRemoveMutationVariables>(
    BillingKeyRemoveDocument,
    options,
  );
}
export type BillingKeyRemoveMutationHookResult = ReturnType<
  typeof useBillingKeyRemoveMutation
>;
export type BillingKeyRemoveMutationResult =
  Apollo.MutationResult<BillingKeyRemoveMutation>;
export type BillingKeyRemoveMutationOptions = Apollo.BaseMutationOptions<
  BillingKeyRemoveMutation,
  BillingKeyRemoveMutationVariables
>;
export const BillingAnalyzerSubscribeDocument = gql`
  mutation billingAnalyzerSubscribe($userId: ID!, $expireDate: Int64!) {
    billingAnalyzerSubscribe(userId: $userId, expireDate: $expireDate)
  }
`;
export type BillingAnalyzerSubscribeMutationFn = Apollo.MutationFunction<
  BillingAnalyzerSubscribeMutation,
  BillingAnalyzerSubscribeMutationVariables
>;

/**
 * __useBillingAnalyzerSubscribeMutation__
 *
 * To run a mutation, you first call `useBillingAnalyzerSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingAnalyzerSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingAnalyzerSubscribeMutation, { data, loading, error }] = useBillingAnalyzerSubscribeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      expireDate: // value for 'expireDate'
 *   },
 * });
 */
export function useBillingAnalyzerSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BillingAnalyzerSubscribeMutation,
    BillingAnalyzerSubscribeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BillingAnalyzerSubscribeMutation,
    BillingAnalyzerSubscribeMutationVariables
  >(BillingAnalyzerSubscribeDocument, options);
}
export type BillingAnalyzerSubscribeMutationHookResult = ReturnType<
  typeof useBillingAnalyzerSubscribeMutation
>;
export type BillingAnalyzerSubscribeMutationResult =
  Apollo.MutationResult<BillingAnalyzerSubscribeMutation>;
export type BillingAnalyzerSubscribeMutationOptions = Apollo.BaseMutationOptions<
  BillingAnalyzerSubscribeMutation,
  BillingAnalyzerSubscribeMutationVariables
>;
export const ExplorerFindBlockByHashDocument = gql`
  query explorerFindBlockByHash($network: Network!, $hash: String!) {
    explorerFindBlockByHash(network: $network, hash: $hash) {
      node {
        hash
        height
        timestamp
      }
      errors
    }
  }
`;

/**
 * __useExplorerFindBlockByHashQuery__
 *
 * To run a query within a React component, call `useExplorerFindBlockByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindBlockByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindBlockByHashQuery({
 *   variables: {
 *      network: // value for 'network'
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useExplorerFindBlockByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindBlockByHashQuery,
    ExplorerFindBlockByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindBlockByHashQuery,
    ExplorerFindBlockByHashQueryVariables
  >(ExplorerFindBlockByHashDocument, options);
}
export function useExplorerFindBlockByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindBlockByHashQuery,
    ExplorerFindBlockByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindBlockByHashQuery,
    ExplorerFindBlockByHashQueryVariables
  >(ExplorerFindBlockByHashDocument, options);
}
export function useExplorerFindBlockByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindBlockByHashQuery,
    ExplorerFindBlockByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindBlockByHashQuery,
    ExplorerFindBlockByHashQueryVariables
  >(ExplorerFindBlockByHashDocument, options);
}
export type ExplorerFindBlockByHashQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByHashQuery
>;
export type ExplorerFindBlockByHashLazyQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByHashLazyQuery
>;
export type ExplorerFindBlockByHashSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByHashSuspenseQuery
>;
export type ExplorerFindBlockByHashQueryResult = Apollo.QueryResult<
  ExplorerFindBlockByHashQuery,
  ExplorerFindBlockByHashQueryVariables
>;
export const ExplorerFindTransactionsInBlockByHashDocument = gql`
  query explorerFindTransactionsInBlockByHash(
    $network: Network!
    $hash: String!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerFindTransactionsInBlockByHash(
      network: $network
      hash: $hash
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...NodeFields
      }
      errors
    }
  }
  ${NodeFieldsFragmentDoc}
`;

/**
 * __useExplorerFindTransactionsInBlockByHashQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsInBlockByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsInBlockByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsInBlockByHashQuery({
 *   variables: {
 *      network: // value for 'network'
 *      hash: // value for 'hash'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useExplorerFindTransactionsInBlockByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsInBlockByHashQuery,
    ExplorerFindTransactionsInBlockByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsInBlockByHashQuery,
    ExplorerFindTransactionsInBlockByHashQueryVariables
  >(ExplorerFindTransactionsInBlockByHashDocument, options);
}
export function useExplorerFindTransactionsInBlockByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsInBlockByHashQuery,
    ExplorerFindTransactionsInBlockByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsInBlockByHashQuery,
    ExplorerFindTransactionsInBlockByHashQueryVariables
  >(ExplorerFindTransactionsInBlockByHashDocument, options);
}
export function useExplorerFindTransactionsInBlockByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsInBlockByHashQuery,
    ExplorerFindTransactionsInBlockByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsInBlockByHashQuery,
    ExplorerFindTransactionsInBlockByHashQueryVariables
  >(ExplorerFindTransactionsInBlockByHashDocument, options);
}
export type ExplorerFindTransactionsInBlockByHashQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsInBlockByHashQuery
>;
export type ExplorerFindTransactionsInBlockByHashLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsInBlockByHashLazyQuery
>;
export type ExplorerFindTransactionsInBlockByHashSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsInBlockByHashSuspenseQuery
>;
export type ExplorerFindTransactionsInBlockByHashQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsInBlockByHashQuery,
  ExplorerFindTransactionsInBlockByHashQueryVariables
>;
export const ExplorerFindAddressByHashDocument = gql`
  query explorerFindAddressByHash($network: Network!, $address: String!) {
    explorerFindAddressByHash(network: $network, address: $address) {
      node {
        ...FindAddressByHashNodeFields
      }
      errors
    }
  }
  ${FindAddressByHashNodeFieldsFragmentDoc}
`;

/**
 * __useExplorerFindAddressByHashQuery__
 *
 * To run a query within a React component, call `useExplorerFindAddressByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindAddressByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindAddressByHashQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerFindAddressByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindAddressByHashQuery,
    ExplorerFindAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindAddressByHashQuery,
    ExplorerFindAddressByHashQueryVariables
  >(ExplorerFindAddressByHashDocument, options);
}
export function useExplorerFindAddressByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindAddressByHashQuery,
    ExplorerFindAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindAddressByHashQuery,
    ExplorerFindAddressByHashQueryVariables
  >(ExplorerFindAddressByHashDocument, options);
}
export function useExplorerFindAddressByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindAddressByHashQuery,
    ExplorerFindAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindAddressByHashQuery,
    ExplorerFindAddressByHashQueryVariables
  >(ExplorerFindAddressByHashDocument, options);
}
export type ExplorerFindAddressByHashQueryHookResult = ReturnType<
  typeof useExplorerFindAddressByHashQuery
>;
export type ExplorerFindAddressByHashLazyQueryHookResult = ReturnType<
  typeof useExplorerFindAddressByHashLazyQuery
>;
export type ExplorerFindAddressByHashSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindAddressByHashSuspenseQuery
>;
export type ExplorerFindAddressByHashQueryResult = Apollo.QueryResult<
  ExplorerFindAddressByHashQuery,
  ExplorerFindAddressByHashQueryVariables
>;
export const ExplorerFindTransactionsByAddressDocument = gql`
  query explorerFindTransactionsByAddress(
    $network: Network!
    $filter: AddressTransactionsFilter!
  ) {
    explorerFindTransactionsByAddress(network: $network, filter: $filter) {
      edge {
        ...AddressTransactionFields
      }
      errors
    }
  }
  ${AddressTransactionFieldsFragmentDoc}
`;

/**
 * __useExplorerFindTransactionsByAddressQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsByAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsByAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsByAddressQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplorerFindTransactionsByAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsByAddressQuery,
    ExplorerFindTransactionsByAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsByAddressQuery,
    ExplorerFindTransactionsByAddressQueryVariables
  >(ExplorerFindTransactionsByAddressDocument, options);
}
export function useExplorerFindTransactionsByAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsByAddressQuery,
    ExplorerFindTransactionsByAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsByAddressQuery,
    ExplorerFindTransactionsByAddressQueryVariables
  >(ExplorerFindTransactionsByAddressDocument, options);
}
export function useExplorerFindTransactionsByAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsByAddressQuery,
    ExplorerFindTransactionsByAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsByAddressQuery,
    ExplorerFindTransactionsByAddressQueryVariables
  >(ExplorerFindTransactionsByAddressDocument, options);
}
export type ExplorerFindTransactionsByAddressQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByAddressQuery
>;
export type ExplorerFindTransactionsByAddressLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByAddressLazyQuery
>;
export type ExplorerFindTransactionsByAddressSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByAddressSuspenseQuery
>;
export type ExplorerFindTransactionsByAddressQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsByAddressQuery,
  ExplorerFindTransactionsByAddressQueryVariables
>;
export const ExplorerFindTransactionsByClusterDocument = gql`
  query explorerFindTransactionsByCluster(
    $network: Network!
    $filter: ClusterTransactionsFilter!
  ) {
    explorerFindTransactionsByCluster(network: $network, filter: $filter) {
      edge {
        ...AddressTransactionFields
      }
      errors
    }
  }
  ${AddressTransactionFieldsFragmentDoc}
`;

/**
 * __useExplorerFindTransactionsByClusterQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsByClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsByClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsByClusterQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplorerFindTransactionsByClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsByClusterQuery,
    ExplorerFindTransactionsByClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsByClusterQuery,
    ExplorerFindTransactionsByClusterQueryVariables
  >(ExplorerFindTransactionsByClusterDocument, options);
}
export function useExplorerFindTransactionsByClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsByClusterQuery,
    ExplorerFindTransactionsByClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsByClusterQuery,
    ExplorerFindTransactionsByClusterQueryVariables
  >(ExplorerFindTransactionsByClusterDocument, options);
}
export function useExplorerFindTransactionsByClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsByClusterQuery,
    ExplorerFindTransactionsByClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsByClusterQuery,
    ExplorerFindTransactionsByClusterQueryVariables
  >(ExplorerFindTransactionsByClusterDocument, options);
}
export type ExplorerFindTransactionsByClusterQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByClusterQuery
>;
export type ExplorerFindTransactionsByClusterLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByClusterLazyQuery
>;
export type ExplorerFindTransactionsByClusterSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByClusterSuspenseQuery
>;
export type ExplorerFindTransactionsByClusterQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsByClusterQuery,
  ExplorerFindTransactionsByClusterQueryVariables
>;
export const ExplorerFindTransactionsByAddressCountDocument = gql`
  query explorerFindTransactionsByAddressCount(
    $network: Network!
    $filter: AddressTransactionsCountFilter!
  ) {
    explorerFindTransactionsByAddressCount(network: $network, filter: $filter) {
      total
      errors
    }
  }
`;

/**
 * __useExplorerFindTransactionsByAddressCountQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsByAddressCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsByAddressCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsByAddressCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplorerFindTransactionsByAddressCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsByAddressCountQuery,
    ExplorerFindTransactionsByAddressCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsByAddressCountQuery,
    ExplorerFindTransactionsByAddressCountQueryVariables
  >(ExplorerFindTransactionsByAddressCountDocument, options);
}
export function useExplorerFindTransactionsByAddressCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsByAddressCountQuery,
    ExplorerFindTransactionsByAddressCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsByAddressCountQuery,
    ExplorerFindTransactionsByAddressCountQueryVariables
  >(ExplorerFindTransactionsByAddressCountDocument, options);
}
export function useExplorerFindTransactionsByAddressCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsByAddressCountQuery,
    ExplorerFindTransactionsByAddressCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsByAddressCountQuery,
    ExplorerFindTransactionsByAddressCountQueryVariables
  >(ExplorerFindTransactionsByAddressCountDocument, options);
}
export type ExplorerFindTransactionsByAddressCountQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByAddressCountQuery
>;
export type ExplorerFindTransactionsByAddressCountLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByAddressCountLazyQuery
>;
export type ExplorerFindTransactionsByAddressCountSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByAddressCountSuspenseQuery
>;
export type ExplorerFindTransactionsByAddressCountQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsByAddressCountQuery,
  ExplorerFindTransactionsByAddressCountQueryVariables
>;
export const ExplorerFindTransactionsByClusterCountDocument = gql`
  query explorerFindTransactionsByClusterCount(
    $network: Network!
    $filter: ClusterTransactionsCountFilter!
  ) {
    explorerFindTransactionsByClusterCount(network: $network, filter: $filter) {
      total
      errors
    }
  }
`;

/**
 * __useExplorerFindTransactionsByClusterCountQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsByClusterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsByClusterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsByClusterCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplorerFindTransactionsByClusterCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsByClusterCountQuery,
    ExplorerFindTransactionsByClusterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsByClusterCountQuery,
    ExplorerFindTransactionsByClusterCountQueryVariables
  >(ExplorerFindTransactionsByClusterCountDocument, options);
}
export function useExplorerFindTransactionsByClusterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsByClusterCountQuery,
    ExplorerFindTransactionsByClusterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsByClusterCountQuery,
    ExplorerFindTransactionsByClusterCountQueryVariables
  >(ExplorerFindTransactionsByClusterCountDocument, options);
}
export function useExplorerFindTransactionsByClusterCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsByClusterCountQuery,
    ExplorerFindTransactionsByClusterCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsByClusterCountQuery,
    ExplorerFindTransactionsByClusterCountQueryVariables
  >(ExplorerFindTransactionsByClusterCountDocument, options);
}
export type ExplorerFindTransactionsByClusterCountQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByClusterCountQuery
>;
export type ExplorerFindTransactionsByClusterCountLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByClusterCountLazyQuery
>;
export type ExplorerFindTransactionsByClusterCountSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsByClusterCountSuspenseQuery
>;
export type ExplorerFindTransactionsByClusterCountQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsByClusterCountQuery,
  ExplorerFindTransactionsByClusterCountQueryVariables
>;
export const ExplorerFindMentionsForAddressDocument = gql`
  query explorerFindMentionsForAddress($network: Network!, $address: String!) {
    explorerFindMentionsForAddress(network: $network, address: $address) {
      edge {
        ...MentionFields
      }
      errors
    }
  }
  ${MentionFieldsFragmentDoc}
`;

/**
 * __useExplorerFindMentionsForAddressQuery__
 *
 * To run a query within a React component, call `useExplorerFindMentionsForAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindMentionsForAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindMentionsForAddressQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerFindMentionsForAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindMentionsForAddressQuery,
    ExplorerFindMentionsForAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindMentionsForAddressQuery,
    ExplorerFindMentionsForAddressQueryVariables
  >(ExplorerFindMentionsForAddressDocument, options);
}
export function useExplorerFindMentionsForAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindMentionsForAddressQuery,
    ExplorerFindMentionsForAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindMentionsForAddressQuery,
    ExplorerFindMentionsForAddressQueryVariables
  >(ExplorerFindMentionsForAddressDocument, options);
}
export function useExplorerFindMentionsForAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindMentionsForAddressQuery,
    ExplorerFindMentionsForAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindMentionsForAddressQuery,
    ExplorerFindMentionsForAddressQueryVariables
  >(ExplorerFindMentionsForAddressDocument, options);
}
export type ExplorerFindMentionsForAddressQueryHookResult = ReturnType<
  typeof useExplorerFindMentionsForAddressQuery
>;
export type ExplorerFindMentionsForAddressLazyQueryHookResult = ReturnType<
  typeof useExplorerFindMentionsForAddressLazyQuery
>;
export type ExplorerFindMentionsForAddressSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindMentionsForAddressSuspenseQuery
>;
export type ExplorerFindMentionsForAddressQueryResult = Apollo.QueryResult<
  ExplorerFindMentionsForAddressQuery,
  ExplorerFindMentionsForAddressQueryVariables
>;
export const ExplorerRiskDocument = gql`
  query explorerRisk($network: Network!, $address: String!) {
    explorerRisk(network: $network, address: $address) {
      risk {
        ...RiskFields
      }
      errors
    }
    explorerAddressCategories(network: $network, address: $address) {
      categories {
        number
        name
        value
      }
      errors
    }
  }
  ${RiskFieldsFragmentDoc}
`;

/**
 * __useExplorerRiskQuery__
 *
 * To run a query within a React component, call `useExplorerRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerRiskQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerRiskQuery(
  baseOptions: Apollo.QueryHookOptions<ExplorerRiskQuery, ExplorerRiskQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerRiskQuery, ExplorerRiskQueryVariables>(
    ExplorerRiskDocument,
    options,
  );
}
export function useExplorerRiskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerRiskQuery,
    ExplorerRiskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorerRiskQuery, ExplorerRiskQueryVariables>(
    ExplorerRiskDocument,
    options,
  );
}
export function useExplorerRiskSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerRiskQuery,
    ExplorerRiskQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorerRiskQuery, ExplorerRiskQueryVariables>(
    ExplorerRiskDocument,
    options,
  );
}
export type ExplorerRiskQueryHookResult = ReturnType<typeof useExplorerRiskQuery>;
export type ExplorerRiskLazyQueryHookResult = ReturnType<typeof useExplorerRiskLazyQuery>;
export type ExplorerRiskSuspenseQueryHookResult = ReturnType<
  typeof useExplorerRiskSuspenseQuery
>;
export type ExplorerRiskQueryResult = Apollo.QueryResult<
  ExplorerRiskQuery,
  ExplorerRiskQueryVariables
>;
export const ExplorerFindTransactionByHashDocument = gql`
  query explorerFindTransactionByHash($network: Network!, $id: String!) {
    explorerFindTransactionByHash(network: $network, id: $id) {
      transaction {
        ...TransactionFields
      }
    }
  }
  ${TransactionFieldsFragmentDoc}
`;

/**
 * __useExplorerFindTransactionByHashQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionByHashQuery({
 *   variables: {
 *      network: // value for 'network'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExplorerFindTransactionByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionByHashQuery,
    ExplorerFindTransactionByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionByHashQuery,
    ExplorerFindTransactionByHashQueryVariables
  >(ExplorerFindTransactionByHashDocument, options);
}
export function useExplorerFindTransactionByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionByHashQuery,
    ExplorerFindTransactionByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionByHashQuery,
    ExplorerFindTransactionByHashQueryVariables
  >(ExplorerFindTransactionByHashDocument, options);
}
export function useExplorerFindTransactionByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionByHashQuery,
    ExplorerFindTransactionByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionByHashQuery,
    ExplorerFindTransactionByHashQueryVariables
  >(ExplorerFindTransactionByHashDocument, options);
}
export type ExplorerFindTransactionByHashQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionByHashQuery
>;
export type ExplorerFindTransactionByHashLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionByHashLazyQuery
>;
export type ExplorerFindTransactionByHashSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionByHashSuspenseQuery
>;
export type ExplorerFindTransactionByHashQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionByHashQuery,
  ExplorerFindTransactionByHashQueryVariables
>;
export const ExplorerIncomingAddressListByTransactionDocument = gql`
  query explorerIncomingAddressListByTransaction(
    $network: Network!
    $txid: String!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerIncomingAddressListByTransaction(
      network: $network
      txid: $txid
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...AddressByTransactionFields
      }
      errors
    }
  }
  ${AddressByTransactionFieldsFragmentDoc}
`;

/**
 * __useExplorerIncomingAddressListByTransactionQuery__
 *
 * To run a query within a React component, call `useExplorerIncomingAddressListByTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerIncomingAddressListByTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerIncomingAddressListByTransactionQuery({
 *   variables: {
 *      network: // value for 'network'
 *      txid: // value for 'txid'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useExplorerIncomingAddressListByTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerIncomingAddressListByTransactionQuery,
    ExplorerIncomingAddressListByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerIncomingAddressListByTransactionQuery,
    ExplorerIncomingAddressListByTransactionQueryVariables
  >(ExplorerIncomingAddressListByTransactionDocument, options);
}
export function useExplorerIncomingAddressListByTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerIncomingAddressListByTransactionQuery,
    ExplorerIncomingAddressListByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerIncomingAddressListByTransactionQuery,
    ExplorerIncomingAddressListByTransactionQueryVariables
  >(ExplorerIncomingAddressListByTransactionDocument, options);
}
export function useExplorerIncomingAddressListByTransactionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerIncomingAddressListByTransactionQuery,
    ExplorerIncomingAddressListByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerIncomingAddressListByTransactionQuery,
    ExplorerIncomingAddressListByTransactionQueryVariables
  >(ExplorerIncomingAddressListByTransactionDocument, options);
}
export type ExplorerIncomingAddressListByTransactionQueryHookResult = ReturnType<
  typeof useExplorerIncomingAddressListByTransactionQuery
>;
export type ExplorerIncomingAddressListByTransactionLazyQueryHookResult = ReturnType<
  typeof useExplorerIncomingAddressListByTransactionLazyQuery
>;
export type ExplorerIncomingAddressListByTransactionSuspenseQueryHookResult = ReturnType<
  typeof useExplorerIncomingAddressListByTransactionSuspenseQuery
>;
export type ExplorerIncomingAddressListByTransactionQueryResult = Apollo.QueryResult<
  ExplorerIncomingAddressListByTransactionQuery,
  ExplorerIncomingAddressListByTransactionQueryVariables
>;
export const ExplorerOutcomingAddressListByTransactionDocument = gql`
  query explorerOutcomingAddressListByTransaction(
    $network: Network!
    $txid: String!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerOutcomingAddressListByTransaction(
      network: $network
      txid: $txid
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...AddressByTransactionFields
      }
      errors
    }
  }
  ${AddressByTransactionFieldsFragmentDoc}
`;

/**
 * __useExplorerOutcomingAddressListByTransactionQuery__
 *
 * To run a query within a React component, call `useExplorerOutcomingAddressListByTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerOutcomingAddressListByTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerOutcomingAddressListByTransactionQuery({
 *   variables: {
 *      network: // value for 'network'
 *      txid: // value for 'txid'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useExplorerOutcomingAddressListByTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerOutcomingAddressListByTransactionQuery,
    ExplorerOutcomingAddressListByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerOutcomingAddressListByTransactionQuery,
    ExplorerOutcomingAddressListByTransactionQueryVariables
  >(ExplorerOutcomingAddressListByTransactionDocument, options);
}
export function useExplorerOutcomingAddressListByTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerOutcomingAddressListByTransactionQuery,
    ExplorerOutcomingAddressListByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerOutcomingAddressListByTransactionQuery,
    ExplorerOutcomingAddressListByTransactionQueryVariables
  >(ExplorerOutcomingAddressListByTransactionDocument, options);
}
export function useExplorerOutcomingAddressListByTransactionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerOutcomingAddressListByTransactionQuery,
    ExplorerOutcomingAddressListByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerOutcomingAddressListByTransactionQuery,
    ExplorerOutcomingAddressListByTransactionQueryVariables
  >(ExplorerOutcomingAddressListByTransactionDocument, options);
}
export type ExplorerOutcomingAddressListByTransactionQueryHookResult = ReturnType<
  typeof useExplorerOutcomingAddressListByTransactionQuery
>;
export type ExplorerOutcomingAddressListByTransactionLazyQueryHookResult = ReturnType<
  typeof useExplorerOutcomingAddressListByTransactionLazyQuery
>;
export type ExplorerOutcomingAddressListByTransactionSuspenseQueryHookResult = ReturnType<
  typeof useExplorerOutcomingAddressListByTransactionSuspenseQuery
>;
export type ExplorerOutcomingAddressListByTransactionQueryResult = Apollo.QueryResult<
  ExplorerOutcomingAddressListByTransactionQuery,
  ExplorerOutcomingAddressListByTransactionQueryVariables
>;
export const ExplorerFindBlockByTransactionDocument = gql`
  query explorerFindBlockByTransaction($network: Network!, $txid: String!) {
    explorerFindBlockByTransaction(network: $network, txid: $txid) {
      node {
        ...BlockchainBlockFields
      }
      errors
    }
  }
  ${BlockchainBlockFieldsFragmentDoc}
`;

/**
 * __useExplorerFindBlockByTransactionQuery__
 *
 * To run a query within a React component, call `useExplorerFindBlockByTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindBlockByTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindBlockByTransactionQuery({
 *   variables: {
 *      network: // value for 'network'
 *      txid: // value for 'txid'
 *   },
 * });
 */
export function useExplorerFindBlockByTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindBlockByTransactionQuery,
    ExplorerFindBlockByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindBlockByTransactionQuery,
    ExplorerFindBlockByTransactionQueryVariables
  >(ExplorerFindBlockByTransactionDocument, options);
}
export function useExplorerFindBlockByTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindBlockByTransactionQuery,
    ExplorerFindBlockByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindBlockByTransactionQuery,
    ExplorerFindBlockByTransactionQueryVariables
  >(ExplorerFindBlockByTransactionDocument, options);
}
export function useExplorerFindBlockByTransactionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindBlockByTransactionQuery,
    ExplorerFindBlockByTransactionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindBlockByTransactionQuery,
    ExplorerFindBlockByTransactionQueryVariables
  >(ExplorerFindBlockByTransactionDocument, options);
}
export type ExplorerFindBlockByTransactionQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByTransactionQuery
>;
export type ExplorerFindBlockByTransactionLazyQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByTransactionLazyQuery
>;
export type ExplorerFindBlockByTransactionSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByTransactionSuspenseQuery
>;
export type ExplorerFindBlockByTransactionQueryResult = Apollo.QueryResult<
  ExplorerFindBlockByTransactionQuery,
  ExplorerFindBlockByTransactionQueryVariables
>;
export const ExplorerFindBlockByNumberDocument = gql`
  query explorerFindBlockByNumber($network: Network!, $height: Int64!) {
    explorerFindBlockByHeight(network: $network, height: $height) {
      node {
        ...BlockchainBlockFields
      }
      errors
    }
  }
  ${BlockchainBlockFieldsFragmentDoc}
`;

/**
 * __useExplorerFindBlockByNumberQuery__
 *
 * To run a query within a React component, call `useExplorerFindBlockByNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindBlockByNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindBlockByNumberQuery({
 *   variables: {
 *      network: // value for 'network'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useExplorerFindBlockByNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindBlockByNumberQuery,
    ExplorerFindBlockByNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindBlockByNumberQuery,
    ExplorerFindBlockByNumberQueryVariables
  >(ExplorerFindBlockByNumberDocument, options);
}
export function useExplorerFindBlockByNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindBlockByNumberQuery,
    ExplorerFindBlockByNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindBlockByNumberQuery,
    ExplorerFindBlockByNumberQueryVariables
  >(ExplorerFindBlockByNumberDocument, options);
}
export function useExplorerFindBlockByNumberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindBlockByNumberQuery,
    ExplorerFindBlockByNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindBlockByNumberQuery,
    ExplorerFindBlockByNumberQueryVariables
  >(ExplorerFindBlockByNumberDocument, options);
}
export type ExplorerFindBlockByNumberQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByNumberQuery
>;
export type ExplorerFindBlockByNumberLazyQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByNumberLazyQuery
>;
export type ExplorerFindBlockByNumberSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindBlockByNumberSuspenseQuery
>;
export type ExplorerFindBlockByNumberQueryResult = Apollo.QueryResult<
  ExplorerFindBlockByNumberQuery,
  ExplorerFindBlockByNumberQueryVariables
>;
export const ExplorerFindTransactionsInBlockDocument = gql`
  query explorerFindTransactionsInBlock(
    $network: Network!
    $height: Int64!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerFindTransactionsInBlock(
      network: $network
      height: $height
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...NodeFields
      }
      errors
    }
  }
  ${NodeFieldsFragmentDoc}
`;

/**
 * __useExplorerFindTransactionsInBlockQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsInBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsInBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsInBlockQuery({
 *   variables: {
 *      network: // value for 'network'
 *      height: // value for 'height'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useExplorerFindTransactionsInBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsInBlockQuery,
    ExplorerFindTransactionsInBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsInBlockQuery,
    ExplorerFindTransactionsInBlockQueryVariables
  >(ExplorerFindTransactionsInBlockDocument, options);
}
export function useExplorerFindTransactionsInBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsInBlockQuery,
    ExplorerFindTransactionsInBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsInBlockQuery,
    ExplorerFindTransactionsInBlockQueryVariables
  >(ExplorerFindTransactionsInBlockDocument, options);
}
export function useExplorerFindTransactionsInBlockSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsInBlockQuery,
    ExplorerFindTransactionsInBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsInBlockQuery,
    ExplorerFindTransactionsInBlockQueryVariables
  >(ExplorerFindTransactionsInBlockDocument, options);
}
export type ExplorerFindTransactionsInBlockQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsInBlockQuery
>;
export type ExplorerFindTransactionsInBlockLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsInBlockLazyQuery
>;
export type ExplorerFindTransactionsInBlockSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsInBlockSuspenseQuery
>;
export type ExplorerFindTransactionsInBlockQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsInBlockQuery,
  ExplorerFindTransactionsInBlockQueryVariables
>;
export const ExplorerFindTransactionsWithBlockDocument = gql`
  query explorerFindTransactionsWithBlock(
    $network: Network!
    $height: Int64!
    $page: Int!
    $pageSize: Int!
  ) {
    explorerFindBlockByHeight(network: $network, height: $height) {
      node {
        ...BlockchainBlockFields
      }
      errors
    }
    explorerFindTransactionsInBlock(
      network: $network
      height: $height
      page: $page
      pageSize: $pageSize
    ) {
      total
      edge {
        ...NodeFields
      }
      errors
    }
  }
  ${BlockchainBlockFieldsFragmentDoc}
  ${NodeFieldsFragmentDoc}
`;

/**
 * __useExplorerFindTransactionsWithBlockQuery__
 *
 * To run a query within a React component, call `useExplorerFindTransactionsWithBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindTransactionsWithBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindTransactionsWithBlockQuery({
 *   variables: {
 *      network: // value for 'network'
 *      height: // value for 'height'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useExplorerFindTransactionsWithBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindTransactionsWithBlockQuery,
    ExplorerFindTransactionsWithBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindTransactionsWithBlockQuery,
    ExplorerFindTransactionsWithBlockQueryVariables
  >(ExplorerFindTransactionsWithBlockDocument, options);
}
export function useExplorerFindTransactionsWithBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindTransactionsWithBlockQuery,
    ExplorerFindTransactionsWithBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindTransactionsWithBlockQuery,
    ExplorerFindTransactionsWithBlockQueryVariables
  >(ExplorerFindTransactionsWithBlockDocument, options);
}
export function useExplorerFindTransactionsWithBlockSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindTransactionsWithBlockQuery,
    ExplorerFindTransactionsWithBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindTransactionsWithBlockQuery,
    ExplorerFindTransactionsWithBlockQueryVariables
  >(ExplorerFindTransactionsWithBlockDocument, options);
}
export type ExplorerFindTransactionsWithBlockQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsWithBlockQuery
>;
export type ExplorerFindTransactionsWithBlockLazyQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsWithBlockLazyQuery
>;
export type ExplorerFindTransactionsWithBlockSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindTransactionsWithBlockSuspenseQuery
>;
export type ExplorerFindTransactionsWithBlockQueryResult = Apollo.QueryResult<
  ExplorerFindTransactionsWithBlockQuery,
  ExplorerFindTransactionsWithBlockQueryVariables
>;
export const ExplorerClusterDocument = gql`
  query explorerCluster($network: Network!, $id: String!) {
    explorerCluster(network: $network, id: $id) {
      cluster {
        ...ClusterFields
      }
    }
    explorerClusterStats(network: $network, id: $id) {
      stats {
        addressCount
        firstTx
        lastTx
        totalAmount
        totalCount
        sentAmount
        sentCount
        receivedAmount
        receivedCount
      }
    }
  }
  ${ClusterFieldsFragmentDoc}
`;

/**
 * __useExplorerClusterQuery__
 *
 * To run a query within a React component, call `useExplorerClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerClusterQuery({
 *   variables: {
 *      network: // value for 'network'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExplorerClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerClusterQuery,
    ExplorerClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerClusterQuery, ExplorerClusterQueryVariables>(
    ExplorerClusterDocument,
    options,
  );
}
export function useExplorerClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerClusterQuery,
    ExplorerClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorerClusterQuery, ExplorerClusterQueryVariables>(
    ExplorerClusterDocument,
    options,
  );
}
export function useExplorerClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerClusterQuery,
    ExplorerClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorerClusterQuery, ExplorerClusterQueryVariables>(
    ExplorerClusterDocument,
    options,
  );
}
export type ExplorerClusterQueryHookResult = ReturnType<typeof useExplorerClusterQuery>;
export type ExplorerClusterLazyQueryHookResult = ReturnType<
  typeof useExplorerClusterLazyQuery
>;
export type ExplorerClusterSuspenseQueryHookResult = ReturnType<
  typeof useExplorerClusterSuspenseQuery
>;
export type ExplorerClusterQueryResult = Apollo.QueryResult<
  ExplorerClusterQuery,
  ExplorerClusterQueryVariables
>;
export const ExplorerFindWalletAddressesDocument = gql`
  query explorerFindWalletAddresses($network: Network!, $filter: WalletAddressesFilter!) {
    explorerFindWalletAddresses(network: $network, filter: $filter) {
      edge {
        address
        lastActivity
        reported
        balance
        turnover
        totalIn
        totalOut
        transactions
      }
      errors
    }
  }
`;

/**
 * __useExplorerFindWalletAddressesQuery__
 *
 * To run a query within a React component, call `useExplorerFindWalletAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindWalletAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindWalletAddressesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplorerFindWalletAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindWalletAddressesQuery,
    ExplorerFindWalletAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindWalletAddressesQuery,
    ExplorerFindWalletAddressesQueryVariables
  >(ExplorerFindWalletAddressesDocument, options);
}
export function useExplorerFindWalletAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindWalletAddressesQuery,
    ExplorerFindWalletAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindWalletAddressesQuery,
    ExplorerFindWalletAddressesQueryVariables
  >(ExplorerFindWalletAddressesDocument, options);
}
export function useExplorerFindWalletAddressesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindWalletAddressesQuery,
    ExplorerFindWalletAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindWalletAddressesQuery,
    ExplorerFindWalletAddressesQueryVariables
  >(ExplorerFindWalletAddressesDocument, options);
}
export type ExplorerFindWalletAddressesQueryHookResult = ReturnType<
  typeof useExplorerFindWalletAddressesQuery
>;
export type ExplorerFindWalletAddressesLazyQueryHookResult = ReturnType<
  typeof useExplorerFindWalletAddressesLazyQuery
>;
export type ExplorerFindWalletAddressesSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindWalletAddressesSuspenseQuery
>;
export type ExplorerFindWalletAddressesQueryResult = Apollo.QueryResult<
  ExplorerFindWalletAddressesQuery,
  ExplorerFindWalletAddressesQueryVariables
>;
export const ExplorerFindWalletAddressesCountDocument = gql`
  query explorerFindWalletAddressesCount(
    $network: Network!
    $filter: WalletAddressesCountFilter!
  ) {
    explorerFindWalletAddressesCount(network: $network, filter: $filter) {
      total
    }
  }
`;

/**
 * __useExplorerFindWalletAddressesCountQuery__
 *
 * To run a query within a React component, call `useExplorerFindWalletAddressesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindWalletAddressesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindWalletAddressesCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplorerFindWalletAddressesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindWalletAddressesCountQuery,
    ExplorerFindWalletAddressesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerFindWalletAddressesCountQuery,
    ExplorerFindWalletAddressesCountQueryVariables
  >(ExplorerFindWalletAddressesCountDocument, options);
}
export function useExplorerFindWalletAddressesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindWalletAddressesCountQuery,
    ExplorerFindWalletAddressesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerFindWalletAddressesCountQuery,
    ExplorerFindWalletAddressesCountQueryVariables
  >(ExplorerFindWalletAddressesCountDocument, options);
}
export function useExplorerFindWalletAddressesCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindWalletAddressesCountQuery,
    ExplorerFindWalletAddressesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindWalletAddressesCountQuery,
    ExplorerFindWalletAddressesCountQueryVariables
  >(ExplorerFindWalletAddressesCountDocument, options);
}
export type ExplorerFindWalletAddressesCountQueryHookResult = ReturnType<
  typeof useExplorerFindWalletAddressesCountQuery
>;
export type ExplorerFindWalletAddressesCountLazyQueryHookResult = ReturnType<
  typeof useExplorerFindWalletAddressesCountLazyQuery
>;
export type ExplorerFindWalletAddressesCountSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindWalletAddressesCountSuspenseQuery
>;
export type ExplorerFindWalletAddressesCountQueryResult = Apollo.QueryResult<
  ExplorerFindWalletAddressesCountQuery,
  ExplorerFindWalletAddressesCountQueryVariables
>;
export const ExplorerClusterForAddressDocument = gql`
  query explorerClusterForAddress($network: Network!, $address: String!) {
    explorerClusterForAddress(network: $network, address: $address) {
      cluster {
        ...ClusterFields
      }
    }
  }
  ${ClusterFieldsFragmentDoc}
`;

/**
 * __useExplorerClusterForAddressQuery__
 *
 * To run a query within a React component, call `useExplorerClusterForAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerClusterForAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerClusterForAddressQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerClusterForAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerClusterForAddressQuery,
    ExplorerClusterForAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerClusterForAddressQuery,
    ExplorerClusterForAddressQueryVariables
  >(ExplorerClusterForAddressDocument, options);
}
export function useExplorerClusterForAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerClusterForAddressQuery,
    ExplorerClusterForAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerClusterForAddressQuery,
    ExplorerClusterForAddressQueryVariables
  >(ExplorerClusterForAddressDocument, options);
}
export function useExplorerClusterForAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerClusterForAddressQuery,
    ExplorerClusterForAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerClusterForAddressQuery,
    ExplorerClusterForAddressQueryVariables
  >(ExplorerClusterForAddressDocument, options);
}
export type ExplorerClusterForAddressQueryHookResult = ReturnType<
  typeof useExplorerClusterForAddressQuery
>;
export type ExplorerClusterForAddressLazyQueryHookResult = ReturnType<
  typeof useExplorerClusterForAddressLazyQuery
>;
export type ExplorerClusterForAddressSuspenseQueryHookResult = ReturnType<
  typeof useExplorerClusterForAddressSuspenseQuery
>;
export type ExplorerClusterForAddressQueryResult = Apollo.QueryResult<
  ExplorerClusterForAddressQuery,
  ExplorerClusterForAddressQueryVariables
>;
export const ExplorerAddressDocument = gql`
  query explorerAddress($network: Network!, $address: String!) {
    explorerAddress(address: $address, network: $network) {
      network
      address
      owner
    }
  }
`;

/**
 * __useExplorerAddressQuery__
 *
 * To run a query within a React component, call `useExplorerAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerAddressQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerAddressQuery,
    ExplorerAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerAddressQuery, ExplorerAddressQueryVariables>(
    ExplorerAddressDocument,
    options,
  );
}
export function useExplorerAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerAddressQuery,
    ExplorerAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorerAddressQuery, ExplorerAddressQueryVariables>(
    ExplorerAddressDocument,
    options,
  );
}
export function useExplorerAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerAddressQuery,
    ExplorerAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorerAddressQuery, ExplorerAddressQueryVariables>(
    ExplorerAddressDocument,
    options,
  );
}
export type ExplorerAddressQueryHookResult = ReturnType<typeof useExplorerAddressQuery>;
export type ExplorerAddressLazyQueryHookResult = ReturnType<
  typeof useExplorerAddressLazyQuery
>;
export type ExplorerAddressSuspenseQueryHookResult = ReturnType<
  typeof useExplorerAddressSuspenseQuery
>;
export type ExplorerAddressQueryResult = Apollo.QueryResult<
  ExplorerAddressQuery,
  ExplorerAddressQueryVariables
>;
export const ExplorerAddressActivityDocument = gql`
  query explorerAddressActivity($network: Network!, $address: String!) {
    explorerAddressActivity(network: $network, address: $address) {
      activity {
        first
        last
      }
      errors
    }
  }
`;

/**
 * __useExplorerAddressActivityQuery__
 *
 * To run a query within a React component, call `useExplorerAddressActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerAddressActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerAddressActivityQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerAddressActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerAddressActivityQuery,
    ExplorerAddressActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerAddressActivityQuery,
    ExplorerAddressActivityQueryVariables
  >(ExplorerAddressActivityDocument, options);
}
export function useExplorerAddressActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerAddressActivityQuery,
    ExplorerAddressActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerAddressActivityQuery,
    ExplorerAddressActivityQueryVariables
  >(ExplorerAddressActivityDocument, options);
}
export function useExplorerAddressActivitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerAddressActivityQuery,
    ExplorerAddressActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerAddressActivityQuery,
    ExplorerAddressActivityQueryVariables
  >(ExplorerAddressActivityDocument, options);
}
export type ExplorerAddressActivityQueryHookResult = ReturnType<
  typeof useExplorerAddressActivityQuery
>;
export type ExplorerAddressActivityLazyQueryHookResult = ReturnType<
  typeof useExplorerAddressActivityLazyQuery
>;
export type ExplorerAddressActivitySuspenseQueryHookResult = ReturnType<
  typeof useExplorerAddressActivitySuspenseQuery
>;
export type ExplorerAddressActivityQueryResult = Apollo.QueryResult<
  ExplorerAddressActivityQuery,
  ExplorerAddressActivityQueryVariables
>;
export const ExplorerAddressTransactionStatsDocument = gql`
  query explorerAddressTransactionStats($network: Network!, $address: String!) {
    explorerAddressTransactionStats(network: $network, address: $address) {
      stats {
        total
        sent
        received
      }
      errors
    }
  }
`;

/**
 * __useExplorerAddressTransactionStatsQuery__
 *
 * To run a query within a React component, call `useExplorerAddressTransactionStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerAddressTransactionStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerAddressTransactionStatsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerAddressTransactionStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerAddressTransactionStatsQuery,
    ExplorerAddressTransactionStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerAddressTransactionStatsQuery,
    ExplorerAddressTransactionStatsQueryVariables
  >(ExplorerAddressTransactionStatsDocument, options);
}
export function useExplorerAddressTransactionStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerAddressTransactionStatsQuery,
    ExplorerAddressTransactionStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerAddressTransactionStatsQuery,
    ExplorerAddressTransactionStatsQueryVariables
  >(ExplorerAddressTransactionStatsDocument, options);
}
export function useExplorerAddressTransactionStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerAddressTransactionStatsQuery,
    ExplorerAddressTransactionStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerAddressTransactionStatsQuery,
    ExplorerAddressTransactionStatsQueryVariables
  >(ExplorerAddressTransactionStatsDocument, options);
}
export type ExplorerAddressTransactionStatsQueryHookResult = ReturnType<
  typeof useExplorerAddressTransactionStatsQuery
>;
export type ExplorerAddressTransactionStatsLazyQueryHookResult = ReturnType<
  typeof useExplorerAddressTransactionStatsLazyQuery
>;
export type ExplorerAddressTransactionStatsSuspenseQueryHookResult = ReturnType<
  typeof useExplorerAddressTransactionStatsSuspenseQuery
>;
export type ExplorerAddressTransactionStatsQueryResult = Apollo.QueryResult<
  ExplorerAddressTransactionStatsQuery,
  ExplorerAddressTransactionStatsQueryVariables
>;
export const ExplorerAddressCategoriesDocument = gql`
  query explorerAddressCategories($network: Network!, $address: String!) {
    explorerAddressCategories(network: $network, address: $address) {
      categories {
        number
        name
        value
      }
      errors
    }
  }
`;

/**
 * __useExplorerAddressCategoriesQuery__
 *
 * To run a query within a React component, call `useExplorerAddressCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerAddressCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerAddressCategoriesQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerAddressCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerAddressCategoriesQuery,
    ExplorerAddressCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerAddressCategoriesQuery,
    ExplorerAddressCategoriesQueryVariables
  >(ExplorerAddressCategoriesDocument, options);
}
export function useExplorerAddressCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerAddressCategoriesQuery,
    ExplorerAddressCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerAddressCategoriesQuery,
    ExplorerAddressCategoriesQueryVariables
  >(ExplorerAddressCategoriesDocument, options);
}
export function useExplorerAddressCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerAddressCategoriesQuery,
    ExplorerAddressCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerAddressCategoriesQuery,
    ExplorerAddressCategoriesQueryVariables
  >(ExplorerAddressCategoriesDocument, options);
}
export type ExplorerAddressCategoriesQueryHookResult = ReturnType<
  typeof useExplorerAddressCategoriesQuery
>;
export type ExplorerAddressCategoriesLazyQueryHookResult = ReturnType<
  typeof useExplorerAddressCategoriesLazyQuery
>;
export type ExplorerAddressCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useExplorerAddressCategoriesSuspenseQuery
>;
export type ExplorerAddressCategoriesQueryResult = Apollo.QueryResult<
  ExplorerAddressCategoriesQuery,
  ExplorerAddressCategoriesQueryVariables
>;
export const ExplorerAddressChartDocument = gql`
  query explorerAddressChart($network: Network!, $address: String!) {
    explorerAddressChart(network: $network, address: $address) {
      charts {
        resdrt
        rescat
        address
        wid
        received
        owner
        reported
        percent
      }
      errors
    }
  }
`;

/**
 * __useExplorerAddressChartQuery__
 *
 * To run a query within a React component, call `useExplorerAddressChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerAddressChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerAddressChartQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerAddressChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerAddressChartQuery,
    ExplorerAddressChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerAddressChartQuery, ExplorerAddressChartQueryVariables>(
    ExplorerAddressChartDocument,
    options,
  );
}
export function useExplorerAddressChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerAddressChartQuery,
    ExplorerAddressChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerAddressChartQuery,
    ExplorerAddressChartQueryVariables
  >(ExplorerAddressChartDocument, options);
}
export function useExplorerAddressChartSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerAddressChartQuery,
    ExplorerAddressChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerAddressChartQuery,
    ExplorerAddressChartQueryVariables
  >(ExplorerAddressChartDocument, options);
}
export type ExplorerAddressChartQueryHookResult = ReturnType<
  typeof useExplorerAddressChartQuery
>;
export type ExplorerAddressChartLazyQueryHookResult = ReturnType<
  typeof useExplorerAddressChartLazyQuery
>;
export type ExplorerAddressChartSuspenseQueryHookResult = ReturnType<
  typeof useExplorerAddressChartSuspenseQuery
>;
export type ExplorerAddressChartQueryResult = Apollo.QueryResult<
  ExplorerAddressChartQuery,
  ExplorerAddressChartQueryVariables
>;
export const ExplorerMarkupDocument = gql`
  query explorerMarkup($network: Network!, $address: String!) {
    explorerMarkup(network: $network, address: $address) {
      errors
      risk {
        risk
      }
      report {
        id
        address
        version
        categoryId
        level
        createdAt
        isPaid
        mentionId
      }
      mention {
        description
        domain
        link
        label
        address
        owner
        risk {
          categoryNumber
          color
        }
        otherAddresses {
          network
          address
        }
        source {
          id
          name
          description
          sourceAddress
          categoryNumber
          version
        }
        reportId
        createdAt
        isPaid
      }
      screenshots
    }
  }
`;

/**
 * __useExplorerMarkupQuery__
 *
 * To run a query within a React component, call `useExplorerMarkupQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerMarkupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerMarkupQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useExplorerMarkupQuery(
  baseOptions: Apollo.QueryHookOptions<ExplorerMarkupQuery, ExplorerMarkupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerMarkupQuery, ExplorerMarkupQueryVariables>(
    ExplorerMarkupDocument,
    options,
  );
}
export function useExplorerMarkupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerMarkupQuery,
    ExplorerMarkupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorerMarkupQuery, ExplorerMarkupQueryVariables>(
    ExplorerMarkupDocument,
    options,
  );
}
export function useExplorerMarkupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerMarkupQuery,
    ExplorerMarkupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorerMarkupQuery, ExplorerMarkupQueryVariables>(
    ExplorerMarkupDocument,
    options,
  );
}
export type ExplorerMarkupQueryHookResult = ReturnType<typeof useExplorerMarkupQuery>;
export type ExplorerMarkupLazyQueryHookResult = ReturnType<
  typeof useExplorerMarkupLazyQuery
>;
export type ExplorerMarkupSuspenseQueryHookResult = ReturnType<
  typeof useExplorerMarkupSuspenseQuery
>;
export type ExplorerMarkupQueryResult = Apollo.QueryResult<
  ExplorerMarkupQuery,
  ExplorerMarkupQueryVariables
>;
export const ExplorerFindMentionDocument = gql`
  query explorerFindMention($network: Network!, $id: ID!) {
    explorerFindMention(network: $network, id: $id) {
      mention {
        ...MentionFields
      }
      screenshots
    }
  }
  ${MentionFieldsFragmentDoc}
`;

/**
 * __useExplorerFindMentionQuery__
 *
 * To run a query within a React component, call `useExplorerFindMentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerFindMentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerFindMentionQuery({
 *   variables: {
 *      network: // value for 'network'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExplorerFindMentionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorerFindMentionQuery,
    ExplorerFindMentionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerFindMentionQuery, ExplorerFindMentionQueryVariables>(
    ExplorerFindMentionDocument,
    options,
  );
}
export function useExplorerFindMentionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerFindMentionQuery,
    ExplorerFindMentionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorerFindMentionQuery, ExplorerFindMentionQueryVariables>(
    ExplorerFindMentionDocument,
    options,
  );
}
export function useExplorerFindMentionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorerFindMentionQuery,
    ExplorerFindMentionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorerFindMentionQuery,
    ExplorerFindMentionQueryVariables
  >(ExplorerFindMentionDocument, options);
}
export type ExplorerFindMentionQueryHookResult = ReturnType<
  typeof useExplorerFindMentionQuery
>;
export type ExplorerFindMentionLazyQueryHookResult = ReturnType<
  typeof useExplorerFindMentionLazyQuery
>;
export type ExplorerFindMentionSuspenseQueryHookResult = ReturnType<
  typeof useExplorerFindMentionSuspenseQuery
>;
export type ExplorerFindMentionQueryResult = Apollo.QueryResult<
  ExplorerFindMentionQuery,
  ExplorerFindMentionQueryVariables
>;
export const MentionSourceListDocument = gql`
  query mentionSourceList {
    mentionSourceList {
      mentionSources {
        id
        name
        description
        sourceAddress
        categoryNumber
        version
      }
    }
  }
`;

/**
 * __useMentionSourceListQuery__
 *
 * To run a query within a React component, call `useMentionSourceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentionSourceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentionSourceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMentionSourceListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MentionSourceListQuery,
    MentionSourceListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MentionSourceListQuery, MentionSourceListQueryVariables>(
    MentionSourceListDocument,
    options,
  );
}
export function useMentionSourceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MentionSourceListQuery,
    MentionSourceListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MentionSourceListQuery, MentionSourceListQueryVariables>(
    MentionSourceListDocument,
    options,
  );
}
export function useMentionSourceListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MentionSourceListQuery,
    MentionSourceListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MentionSourceListQuery, MentionSourceListQueryVariables>(
    MentionSourceListDocument,
    options,
  );
}
export type MentionSourceListQueryHookResult = ReturnType<
  typeof useMentionSourceListQuery
>;
export type MentionSourceListLazyQueryHookResult = ReturnType<
  typeof useMentionSourceListLazyQuery
>;
export type MentionSourceListSuspenseQueryHookResult = ReturnType<
  typeof useMentionSourceListSuspenseQuery
>;
export type MentionSourceListQueryResult = Apollo.QueryResult<
  MentionSourceListQuery,
  MentionSourceListQueryVariables
>;
export const ExplorerMarkupCreateDocument = gql`
  mutation explorerMarkupCreate($req: ExplorerMarkupCreateInput!) {
    explorerMarkupCreate(req: $req) {
      mention {
        ...MentionFields
      }
      screenshots
    }
  }
  ${MentionFieldsFragmentDoc}
`;
export type ExplorerMarkupCreateMutationFn = Apollo.MutationFunction<
  ExplorerMarkupCreateMutation,
  ExplorerMarkupCreateMutationVariables
>;

/**
 * __useExplorerMarkupCreateMutation__
 *
 * To run a mutation, you first call `useExplorerMarkupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExplorerMarkupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [explorerMarkupCreateMutation, { data, loading, error }] = useExplorerMarkupCreateMutation({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useExplorerMarkupCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExplorerMarkupCreateMutation,
    ExplorerMarkupCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExplorerMarkupCreateMutation,
    ExplorerMarkupCreateMutationVariables
  >(ExplorerMarkupCreateDocument, options);
}
export type ExplorerMarkupCreateMutationHookResult = ReturnType<
  typeof useExplorerMarkupCreateMutation
>;
export type ExplorerMarkupCreateMutationResult =
  Apollo.MutationResult<ExplorerMarkupCreateMutation>;
export type ExplorerMarkupCreateMutationOptions = Apollo.BaseMutationOptions<
  ExplorerMarkupCreateMutation,
  ExplorerMarkupCreateMutationVariables
>;
export const GraphTransactionsBetweenAddressDocument = gql`
  query graphTransactionsBetweenAddress(
    $network: Network!
    $filter: TransactionsBetweenAddressInput!
  ) {
    explorerTransactionsBetweenAddress(network: $network, filter: $filter) {
      edge {
        ...TransactionBetweenFields
      }
      total
      totalReceive
      totalSent
    }
  }
  ${TransactionBetweenFieldsFragmentDoc}
`;

/**
 * __useGraphTransactionsBetweenAddressQuery__
 *
 * To run a query within a React component, call `useGraphTransactionsBetweenAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphTransactionsBetweenAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphTransactionsBetweenAddressQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGraphTransactionsBetweenAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphTransactionsBetweenAddressQuery,
    GraphTransactionsBetweenAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphTransactionsBetweenAddressQuery,
    GraphTransactionsBetweenAddressQueryVariables
  >(GraphTransactionsBetweenAddressDocument, options);
}
export function useGraphTransactionsBetweenAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphTransactionsBetweenAddressQuery,
    GraphTransactionsBetweenAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphTransactionsBetweenAddressQuery,
    GraphTransactionsBetweenAddressQueryVariables
  >(GraphTransactionsBetweenAddressDocument, options);
}
export function useGraphTransactionsBetweenAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphTransactionsBetweenAddressQuery,
    GraphTransactionsBetweenAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GraphTransactionsBetweenAddressQuery,
    GraphTransactionsBetweenAddressQueryVariables
  >(GraphTransactionsBetweenAddressDocument, options);
}
export type GraphTransactionsBetweenAddressQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenAddressQuery
>;
export type GraphTransactionsBetweenAddressLazyQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenAddressLazyQuery
>;
export type GraphTransactionsBetweenAddressSuspenseQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenAddressSuspenseQuery
>;
export type GraphTransactionsBetweenAddressQueryResult = Apollo.QueryResult<
  GraphTransactionsBetweenAddressQuery,
  GraphTransactionsBetweenAddressQueryVariables
>;
export const GraphTransactionsBetweenAddressAndClusterDocument = gql`
  query graphTransactionsBetweenAddressAndCluster(
    $network: Network!
    $filter: TransactionsBetweenAddressAndClusterInput!
  ) {
    explorerTransactionsBetweenAddressAndCluster(network: $network, filter: $filter) {
      edge {
        ...TransactionBetweenFields
      }
    }
  }
  ${TransactionBetweenFieldsFragmentDoc}
`;

/**
 * __useGraphTransactionsBetweenAddressAndClusterQuery__
 *
 * To run a query within a React component, call `useGraphTransactionsBetweenAddressAndClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphTransactionsBetweenAddressAndClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphTransactionsBetweenAddressAndClusterQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGraphTransactionsBetweenAddressAndClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphTransactionsBetweenAddressAndClusterQuery,
    GraphTransactionsBetweenAddressAndClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphTransactionsBetweenAddressAndClusterQuery,
    GraphTransactionsBetweenAddressAndClusterQueryVariables
  >(GraphTransactionsBetweenAddressAndClusterDocument, options);
}
export function useGraphTransactionsBetweenAddressAndClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphTransactionsBetweenAddressAndClusterQuery,
    GraphTransactionsBetweenAddressAndClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphTransactionsBetweenAddressAndClusterQuery,
    GraphTransactionsBetweenAddressAndClusterQueryVariables
  >(GraphTransactionsBetweenAddressAndClusterDocument, options);
}
export function useGraphTransactionsBetweenAddressAndClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphTransactionsBetweenAddressAndClusterQuery,
    GraphTransactionsBetweenAddressAndClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GraphTransactionsBetweenAddressAndClusterQuery,
    GraphTransactionsBetweenAddressAndClusterQueryVariables
  >(GraphTransactionsBetweenAddressAndClusterDocument, options);
}
export type GraphTransactionsBetweenAddressAndClusterQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenAddressAndClusterQuery
>;
export type GraphTransactionsBetweenAddressAndClusterLazyQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenAddressAndClusterLazyQuery
>;
export type GraphTransactionsBetweenAddressAndClusterSuspenseQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenAddressAndClusterSuspenseQuery
>;
export type GraphTransactionsBetweenAddressAndClusterQueryResult = Apollo.QueryResult<
  GraphTransactionsBetweenAddressAndClusterQuery,
  GraphTransactionsBetweenAddressAndClusterQueryVariables
>;
export const GraphTransactionsBetweenClusterAndClusterDocument = gql`
  query graphTransactionsBetweenClusterAndCluster(
    $network: Network!
    $filter: TransactionsBetweenClusterAndClusterInput!
  ) {
    explorerTransactionsBetweenClusterAndCluster(network: $network, filter: $filter) {
      edge {
        ...TransactionBetweenFields
      }
    }
  }
  ${TransactionBetweenFieldsFragmentDoc}
`;

/**
 * __useGraphTransactionsBetweenClusterAndClusterQuery__
 *
 * To run a query within a React component, call `useGraphTransactionsBetweenClusterAndClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphTransactionsBetweenClusterAndClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphTransactionsBetweenClusterAndClusterQuery({
 *   variables: {
 *      network: // value for 'network'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGraphTransactionsBetweenClusterAndClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphTransactionsBetweenClusterAndClusterQuery,
    GraphTransactionsBetweenClusterAndClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphTransactionsBetweenClusterAndClusterQuery,
    GraphTransactionsBetweenClusterAndClusterQueryVariables
  >(GraphTransactionsBetweenClusterAndClusterDocument, options);
}
export function useGraphTransactionsBetweenClusterAndClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphTransactionsBetweenClusterAndClusterQuery,
    GraphTransactionsBetweenClusterAndClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphTransactionsBetweenClusterAndClusterQuery,
    GraphTransactionsBetweenClusterAndClusterQueryVariables
  >(GraphTransactionsBetweenClusterAndClusterDocument, options);
}
export function useGraphTransactionsBetweenClusterAndClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphTransactionsBetweenClusterAndClusterQuery,
    GraphTransactionsBetweenClusterAndClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GraphTransactionsBetweenClusterAndClusterQuery,
    GraphTransactionsBetweenClusterAndClusterQueryVariables
  >(GraphTransactionsBetweenClusterAndClusterDocument, options);
}
export type GraphTransactionsBetweenClusterAndClusterQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenClusterAndClusterQuery
>;
export type GraphTransactionsBetweenClusterAndClusterLazyQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenClusterAndClusterLazyQuery
>;
export type GraphTransactionsBetweenClusterAndClusterSuspenseQueryHookResult = ReturnType<
  typeof useGraphTransactionsBetweenClusterAndClusterSuspenseQuery
>;
export type GraphTransactionsBetweenClusterAndClusterQueryResult = Apollo.QueryResult<
  GraphTransactionsBetweenClusterAndClusterQuery,
  GraphTransactionsBetweenClusterAndClusterQueryVariables
>;
export const GraphRelationBetweenAddressesDocument = gql`
  query graphRelationBetweenAddresses($params: ExplorerRelationBetweenAddressesReq!) {
    explorerRelationBetweenAddresses(req: $params) {
      relations {
        timestamp
        transactionId
        token
        amount
        address
        direction
      }
    }
  }
`;

/**
 * __useGraphRelationBetweenAddressesQuery__
 *
 * To run a query within a React component, call `useGraphRelationBetweenAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphRelationBetweenAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphRelationBetweenAddressesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGraphRelationBetweenAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphRelationBetweenAddressesQuery,
    GraphRelationBetweenAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphRelationBetweenAddressesQuery,
    GraphRelationBetweenAddressesQueryVariables
  >(GraphRelationBetweenAddressesDocument, options);
}
export function useGraphRelationBetweenAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphRelationBetweenAddressesQuery,
    GraphRelationBetweenAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphRelationBetweenAddressesQuery,
    GraphRelationBetweenAddressesQueryVariables
  >(GraphRelationBetweenAddressesDocument, options);
}
export function useGraphRelationBetweenAddressesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphRelationBetweenAddressesQuery,
    GraphRelationBetweenAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GraphRelationBetweenAddressesQuery,
    GraphRelationBetweenAddressesQueryVariables
  >(GraphRelationBetweenAddressesDocument, options);
}
export type GraphRelationBetweenAddressesQueryHookResult = ReturnType<
  typeof useGraphRelationBetweenAddressesQuery
>;
export type GraphRelationBetweenAddressesLazyQueryHookResult = ReturnType<
  typeof useGraphRelationBetweenAddressesLazyQuery
>;
export type GraphRelationBetweenAddressesSuspenseQueryHookResult = ReturnType<
  typeof useGraphRelationBetweenAddressesSuspenseQuery
>;
export type GraphRelationBetweenAddressesQueryResult = Apollo.QueryResult<
  GraphRelationBetweenAddressesQuery,
  GraphRelationBetweenAddressesQueryVariables
>;
export const GraphStatsAddressByHashDocument = gql`
  query graphStatsAddressByHash($network: Network!, $address: String!) {
    explorerFindAddressByHash(network: $network, address: $address) {
      node {
        ...FindAddressByHashNodeFields
      }
      errors
    }
    explorerAddressTransactionStats(network: $network, address: $address) {
      stats {
        total
        sent
        received
      }
      errors
    }
  }
  ${FindAddressByHashNodeFieldsFragmentDoc}
`;

/**
 * __useGraphStatsAddressByHashQuery__
 *
 * To run a query within a React component, call `useGraphStatsAddressByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphStatsAddressByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphStatsAddressByHashQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGraphStatsAddressByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphStatsAddressByHashQuery,
    GraphStatsAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphStatsAddressByHashQuery,
    GraphStatsAddressByHashQueryVariables
  >(GraphStatsAddressByHashDocument, options);
}
export function useGraphStatsAddressByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphStatsAddressByHashQuery,
    GraphStatsAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphStatsAddressByHashQuery,
    GraphStatsAddressByHashQueryVariables
  >(GraphStatsAddressByHashDocument, options);
}
export function useGraphStatsAddressByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphStatsAddressByHashQuery,
    GraphStatsAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GraphStatsAddressByHashQuery,
    GraphStatsAddressByHashQueryVariables
  >(GraphStatsAddressByHashDocument, options);
}
export type GraphStatsAddressByHashQueryHookResult = ReturnType<
  typeof useGraphStatsAddressByHashQuery
>;
export type GraphStatsAddressByHashLazyQueryHookResult = ReturnType<
  typeof useGraphStatsAddressByHashLazyQuery
>;
export type GraphStatsAddressByHashSuspenseQueryHookResult = ReturnType<
  typeof useGraphStatsAddressByHashSuspenseQuery
>;
export type GraphStatsAddressByHashQueryResult = Apollo.QueryResult<
  GraphStatsAddressByHashQuery,
  GraphStatsAddressByHashQueryVariables
>;
export const GraphRiskAddressByHashDocument = gql`
  query graphRiskAddressByHash($network: Network!, $address: String!) {
    explorerRisk(network: $network, address: $address) {
      risk {
        ...RiskFields
      }
      errors
    }
  }
  ${RiskFieldsFragmentDoc}
`;

/**
 * __useGraphRiskAddressByHashQuery__
 *
 * To run a query within a React component, call `useGraphRiskAddressByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphRiskAddressByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphRiskAddressByHashQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGraphRiskAddressByHashQuery(
  baseOptions: Apollo.QueryHookOptions<
    GraphRiskAddressByHashQuery,
    GraphRiskAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GraphRiskAddressByHashQuery,
    GraphRiskAddressByHashQueryVariables
  >(GraphRiskAddressByHashDocument, options);
}
export function useGraphRiskAddressByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphRiskAddressByHashQuery,
    GraphRiskAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GraphRiskAddressByHashQuery,
    GraphRiskAddressByHashQueryVariables
  >(GraphRiskAddressByHashDocument, options);
}
export function useGraphRiskAddressByHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphRiskAddressByHashQuery,
    GraphRiskAddressByHashQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GraphRiskAddressByHashQuery,
    GraphRiskAddressByHashQueryVariables
  >(GraphRiskAddressByHashDocument, options);
}
export type GraphRiskAddressByHashQueryHookResult = ReturnType<
  typeof useGraphRiskAddressByHashQuery
>;
export type GraphRiskAddressByHashLazyQueryHookResult = ReturnType<
  typeof useGraphRiskAddressByHashLazyQuery
>;
export type GraphRiskAddressByHashSuspenseQueryHookResult = ReturnType<
  typeof useGraphRiskAddressByHashSuspenseQuery
>;
export type GraphRiskAddressByHashQueryResult = Apollo.QueryResult<
  GraphRiskAddressByHashQuery,
  GraphRiskAddressByHashQueryVariables
>;
export const GraphClusterDocument = gql`
  query graphCluster($network: Network!, $id: String!) {
    explorerCluster(network: $network, id: $id) {
      cluster {
        ...ClusterFields
      }
    }
    explorerClusterStats(network: $network, id: $id) {
      stats {
        addressCount
        firstTx
        lastTx
        totalAmount
        totalCount
        sentAmount
        sentCount
        receivedAmount
        receivedCount
      }
    }
  }
  ${ClusterFieldsFragmentDoc}
`;

/**
 * __useGraphClusterQuery__
 *
 * To run a query within a React component, call `useGraphClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphClusterQuery({
 *   variables: {
 *      network: // value for 'network'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGraphClusterQuery(
  baseOptions: Apollo.QueryHookOptions<GraphClusterQuery, GraphClusterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphClusterQuery, GraphClusterQueryVariables>(
    GraphClusterDocument,
    options,
  );
}
export function useGraphClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GraphClusterQuery,
    GraphClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphClusterQuery, GraphClusterQueryVariables>(
    GraphClusterDocument,
    options,
  );
}
export function useGraphClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphClusterQuery,
    GraphClusterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GraphClusterQuery, GraphClusterQueryVariables>(
    GraphClusterDocument,
    options,
  );
}
export type GraphClusterQueryHookResult = ReturnType<typeof useGraphClusterQuery>;
export type GraphClusterLazyQueryHookResult = ReturnType<typeof useGraphClusterLazyQuery>;
export type GraphClusterSuspenseQueryHookResult = ReturnType<
  typeof useGraphClusterSuspenseQuery
>;
export type GraphClusterQueryResult = Apollo.QueryResult<
  GraphClusterQuery,
  GraphClusterQueryVariables
>;
export const GraphListDocument = gql`
  query graphList($page: Int!, $pageSize: Int!, $order: OrderDirection) {
    graphList(page: $page, pageSize: $pageSize, order: $order) {
      errors
      total
      edge {
        updatedAt
        uuid
        data {
          meta {
            name
            network
          }
        }
      }
    }
  }
`;

/**
 * __useGraphListQuery__
 *
 * To run a query within a React component, call `useGraphListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGraphListQuery(
  baseOptions: Apollo.QueryHookOptions<GraphListQuery, GraphListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphListQuery, GraphListQueryVariables>(
    GraphListDocument,
    options,
  );
}
export function useGraphListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GraphListQuery, GraphListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphListQuery, GraphListQueryVariables>(
    GraphListDocument,
    options,
  );
}
export function useGraphListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GraphListQuery, GraphListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GraphListQuery, GraphListQueryVariables>(
    GraphListDocument,
    options,
  );
}
export type GraphListQueryHookResult = ReturnType<typeof useGraphListQuery>;
export type GraphListLazyQueryHookResult = ReturnType<typeof useGraphListLazyQuery>;
export type GraphListSuspenseQueryHookResult = ReturnType<
  typeof useGraphListSuspenseQuery
>;
export type GraphListQueryResult = Apollo.QueryResult<
  GraphListQuery,
  GraphListQueryVariables
>;
export const GraphByUuidDocument = gql`
  query graphByUUID($uuid: UUID!) {
    graphByUUID(uuid: $uuid) {
      errors
      graph {
        ...GraphFields
      }
    }
  }
  ${GraphFieldsFragmentDoc}
`;

/**
 * __useGraphByUuidQuery__
 *
 * To run a query within a React component, call `useGraphByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGraphByUuidQuery(
  baseOptions: Apollo.QueryHookOptions<GraphByUuidQuery, GraphByUuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GraphByUuidQuery, GraphByUuidQueryVariables>(
    GraphByUuidDocument,
    options,
  );
}
export function useGraphByUuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GraphByUuidQuery, GraphByUuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GraphByUuidQuery, GraphByUuidQueryVariables>(
    GraphByUuidDocument,
    options,
  );
}
export function useGraphByUuidSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GraphByUuidQuery,
    GraphByUuidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GraphByUuidQuery, GraphByUuidQueryVariables>(
    GraphByUuidDocument,
    options,
  );
}
export type GraphByUuidQueryHookResult = ReturnType<typeof useGraphByUuidQuery>;
export type GraphByUuidLazyQueryHookResult = ReturnType<typeof useGraphByUuidLazyQuery>;
export type GraphByUuidSuspenseQueryHookResult = ReturnType<
  typeof useGraphByUuidSuspenseQuery
>;
export type GraphByUuidQueryResult = Apollo.QueryResult<
  GraphByUuidQuery,
  GraphByUuidQueryVariables
>;
export const GraphUpdateDocument = gql`
  mutation graphUpdate($input: GraphDataInput!) {
    graphUpdate(input: $input) {
      errors
    }
  }
`;
export type GraphUpdateMutationFn = Apollo.MutationFunction<
  GraphUpdateMutation,
  GraphUpdateMutationVariables
>;

/**
 * __useGraphUpdateMutation__
 *
 * To run a mutation, you first call `useGraphUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphUpdateMutation, { data, loading, error }] = useGraphUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGraphUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphUpdateMutation,
    GraphUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GraphUpdateMutation, GraphUpdateMutationVariables>(
    GraphUpdateDocument,
    options,
  );
}
export type GraphUpdateMutationHookResult = ReturnType<typeof useGraphUpdateMutation>;
export type GraphUpdateMutationResult = Apollo.MutationResult<GraphUpdateMutation>;
export type GraphUpdateMutationOptions = Apollo.BaseMutationOptions<
  GraphUpdateMutation,
  GraphUpdateMutationVariables
>;
export const GraphSaveDocument = gql`
  mutation graphSave($input: GraphDataInput!) {
    graphSave(input: $input) {
      errors
      uuid
    }
  }
`;
export type GraphSaveMutationFn = Apollo.MutationFunction<
  GraphSaveMutation,
  GraphSaveMutationVariables
>;

/**
 * __useGraphSaveMutation__
 *
 * To run a mutation, you first call `useGraphSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphSaveMutation, { data, loading, error }] = useGraphSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGraphSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<GraphSaveMutation, GraphSaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GraphSaveMutation, GraphSaveMutationVariables>(
    GraphSaveDocument,
    options,
  );
}
export type GraphSaveMutationHookResult = ReturnType<typeof useGraphSaveMutation>;
export type GraphSaveMutationResult = Apollo.MutationResult<GraphSaveMutation>;
export type GraphSaveMutationOptions = Apollo.BaseMutationOptions<
  GraphSaveMutation,
  GraphSaveMutationVariables
>;
export const GraphDeleteDocument = gql`
  mutation graphDelete($uuid: UUID!) {
    graphDelete(uuid: $uuid) {
      errors
    }
  }
`;
export type GraphDeleteMutationFn = Apollo.MutationFunction<
  GraphDeleteMutation,
  GraphDeleteMutationVariables
>;

/**
 * __useGraphDeleteMutation__
 *
 * To run a mutation, you first call `useGraphDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphDeleteMutation, { data, loading, error }] = useGraphDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGraphDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphDeleteMutation,
    GraphDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GraphDeleteMutation, GraphDeleteMutationVariables>(
    GraphDeleteDocument,
    options,
  );
}
export type GraphDeleteMutationHookResult = ReturnType<typeof useGraphDeleteMutation>;
export type GraphDeleteMutationResult = Apollo.MutationResult<GraphDeleteMutation>;
export type GraphDeleteMutationOptions = Apollo.BaseMutationOptions<
  GraphDeleteMutation,
  GraphDeleteMutationVariables
>;
export const GraphRenameDocument = gql`
  mutation graphRename($uuid: UUID!, $name: String!) {
    graphMetaNameUpdate(uuid: $uuid, metaName: $name) {
      errors
    }
  }
`;
export type GraphRenameMutationFn = Apollo.MutationFunction<
  GraphRenameMutation,
  GraphRenameMutationVariables
>;

/**
 * __useGraphRenameMutation__
 *
 * To run a mutation, you first call `useGraphRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphRenameMutation, { data, loading, error }] = useGraphRenameMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGraphRenameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GraphRenameMutation,
    GraphRenameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GraphRenameMutation, GraphRenameMutationVariables>(
    GraphRenameDocument,
    options,
  );
}
export type GraphRenameMutationHookResult = ReturnType<typeof useGraphRenameMutation>;
export type GraphRenameMutationResult = Apollo.MutationResult<GraphRenameMutation>;
export type GraphRenameMutationOptions = Apollo.BaseMutationOptions<
  GraphRenameMutation,
  GraphRenameMutationVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      jwt {
        ...JwtFields
      }
      errors
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const ExplorerMentionRemoveDocument = gql`
  mutation explorerMentionRemove($req: MentionRemoveInput!) {
    explorerMentionRemove(req: $req)
  }
`;
export type ExplorerMentionRemoveMutationFn = Apollo.MutationFunction<
  ExplorerMentionRemoveMutation,
  ExplorerMentionRemoveMutationVariables
>;

/**
 * __useExplorerMentionRemoveMutation__
 *
 * To run a mutation, you first call `useExplorerMentionRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExplorerMentionRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [explorerMentionRemoveMutation, { data, loading, error }] = useExplorerMentionRemoveMutation({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useExplorerMentionRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExplorerMentionRemoveMutation,
    ExplorerMentionRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExplorerMentionRemoveMutation,
    ExplorerMentionRemoveMutationVariables
  >(ExplorerMentionRemoveDocument, options);
}
export type ExplorerMentionRemoveMutationHookResult = ReturnType<
  typeof useExplorerMentionRemoveMutation
>;
export type ExplorerMentionRemoveMutationResult =
  Apollo.MutationResult<ExplorerMentionRemoveMutation>;
export type ExplorerMentionRemoveMutationOptions = Apollo.BaseMutationOptions<
  ExplorerMentionRemoveMutation,
  ExplorerMentionRemoveMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      id
      name
      email
      isActive
      createdAt
      updatedAt
      billing {
        ...BillingFields
      }
    }
  }
  ${BillingFieldsFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RefreshTokenDocument = gql`
  mutation refreshToken {
    refreshToken {
      result {
        ...JwtFields
      }
      errors
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options,
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const RegistrationDocument = gql`
  mutation registration($input: RegistrationInput!) {
    registration(input: $input) {
      state
      errors
    }
  }
`;
export type RegistrationMutationFn = Apollo.MutationFunction<
  RegistrationMutation,
  RegistrationMutationVariables
>;

/**
 * __useRegistrationMutation__
 *
 * To run a mutation, you first call `useRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationMutation, { data, loading, error }] = useRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationMutation,
    RegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegistrationMutation, RegistrationMutationVariables>(
    RegistrationDocument,
    options,
  );
}
export type RegistrationMutationHookResult = ReturnType<typeof useRegistrationMutation>;
export type RegistrationMutationResult = Apollo.MutationResult<RegistrationMutation>;
export type RegistrationMutationOptions = Apollo.BaseMutationOptions<
  RegistrationMutation,
  RegistrationMutationVariables
>;
export const RegistrationConfirmDocument = gql`
  mutation registrationConfirm($input: RegistrationConfirmInput!) {
    registrationConfirm(input: $input) {
      jwt {
        ...JwtFields
      }
      errors
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RegistrationConfirmMutationFn = Apollo.MutationFunction<
  RegistrationConfirmMutation,
  RegistrationConfirmMutationVariables
>;

/**
 * __useRegistrationConfirmMutation__
 *
 * To run a mutation, you first call `useRegistrationConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationConfirmMutation, { data, loading, error }] = useRegistrationConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrationConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationConfirmMutation,
    RegistrationConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationConfirmMutation,
    RegistrationConfirmMutationVariables
  >(RegistrationConfirmDocument, options);
}
export type RegistrationConfirmMutationHookResult = ReturnType<
  typeof useRegistrationConfirmMutation
>;
export type RegistrationConfirmMutationResult =
  Apollo.MutationResult<RegistrationConfirmMutation>;
export type RegistrationConfirmMutationOptions = Apollo.BaseMutationOptions<
  RegistrationConfirmMutation,
  RegistrationConfirmMutationVariables
>;
export const RestoreDocument = gql`
  mutation restore($input: RestoreInput!) {
    restore(input: $input) {
      state
      errors
    }
  }
`;
export type RestoreMutationFn = Apollo.MutationFunction<
  RestoreMutation,
  RestoreMutationVariables
>;

/**
 * __useRestoreMutation__
 *
 * To run a mutation, you first call `useRestoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMutation, { data, loading, error }] = useRestoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreMutation, RestoreMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreMutation, RestoreMutationVariables>(
    RestoreDocument,
    options,
  );
}
export type RestoreMutationHookResult = ReturnType<typeof useRestoreMutation>;
export type RestoreMutationResult = Apollo.MutationResult<RestoreMutation>;
export type RestoreMutationOptions = Apollo.BaseMutationOptions<
  RestoreMutation,
  RestoreMutationVariables
>;
export const RestoreCheckDocument = gql`
  mutation restoreCheck($input: RestoreCheckInput!) {
    restoreCheck(input: $input) {
      state
      errors
    }
  }
`;
export type RestoreCheckMutationFn = Apollo.MutationFunction<
  RestoreCheckMutation,
  RestoreCheckMutationVariables
>;

/**
 * __useRestoreCheckMutation__
 *
 * To run a mutation, you first call `useRestoreCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCheckMutation, { data, loading, error }] = useRestoreCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreCheckMutation,
    RestoreCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreCheckMutation, RestoreCheckMutationVariables>(
    RestoreCheckDocument,
    options,
  );
}
export type RestoreCheckMutationHookResult = ReturnType<typeof useRestoreCheckMutation>;
export type RestoreCheckMutationResult = Apollo.MutationResult<RestoreCheckMutation>;
export type RestoreCheckMutationOptions = Apollo.BaseMutationOptions<
  RestoreCheckMutation,
  RestoreCheckMutationVariables
>;
export const RestoreConfirmDocument = gql`
  mutation restoreConfirm($input: RestoreConfirmInput!) {
    restoreConfirm(input: $input) {
      jwt {
        ...JwtFields
      }
      errors
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RestoreConfirmMutationFn = Apollo.MutationFunction<
  RestoreConfirmMutation,
  RestoreConfirmMutationVariables
>;

/**
 * __useRestoreConfirmMutation__
 *
 * To run a mutation, you first call `useRestoreConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreConfirmMutation, { data, loading, error }] = useRestoreConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreConfirmMutation,
    RestoreConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreConfirmMutation, RestoreConfirmMutationVariables>(
    RestoreConfirmDocument,
    options,
  );
}
export type RestoreConfirmMutationHookResult = ReturnType<
  typeof useRestoreConfirmMutation
>;
export type RestoreConfirmMutationResult = Apollo.MutationResult<RestoreConfirmMutation>;
export type RestoreConfirmMutationOptions = Apollo.BaseMutationOptions<
  RestoreConfirmMutation,
  RestoreConfirmMutationVariables
>;
export const SubscribeDocument = gql`
  mutation subscribe($input: SubscribeInput!) {
    subscribe(input: $input)
  }
`;
export type SubscribeMutationFn = Apollo.MutationFunction<
  SubscribeMutation,
  SubscribeMutationVariables
>;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
    options,
  );
}
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = Apollo.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = Apollo.BaseMutationOptions<
  SubscribeMutation,
  SubscribeMutationVariables
>;
export const UnsubscribeDocument = gql`
  mutation unsubscribe($input: SubscribeInput!) {
    unsubscribe(input: $input)
  }
`;
export type UnsubscribeMutationFn = Apollo.MutationFunction<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeMutation,
    UnsubscribeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(
    UnsubscribeDocument,
    options,
  );
}
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = Apollo.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeMutation,
  UnsubscribeMutationVariables
>;
export const SubscribedDocument = gql`
  query subscribed($input: SubscribeInput!) {
    subscribed(input: $input)
  }
`;

/**
 * __useSubscribedQuery__
 *
 * To run a query within a React component, call `useSubscribedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribedQuery(
  baseOptions: Apollo.QueryHookOptions<SubscribedQuery, SubscribedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscribedQuery, SubscribedQueryVariables>(
    SubscribedDocument,
    options,
  );
}
export function useSubscribedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscribedQuery, SubscribedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscribedQuery, SubscribedQueryVariables>(
    SubscribedDocument,
    options,
  );
}
export function useSubscribedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubscribedQuery,
    SubscribedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubscribedQuery, SubscribedQueryVariables>(
    SubscribedDocument,
    options,
  );
}
export type SubscribedQueryHookResult = ReturnType<typeof useSubscribedQuery>;
export type SubscribedLazyQueryHookResult = ReturnType<typeof useSubscribedLazyQuery>;
export type SubscribedSuspenseQueryHookResult = ReturnType<
  typeof useSubscribedSuspenseQuery
>;
export type SubscribedQueryResult = Apollo.QueryResult<
  SubscribedQuery,
  SubscribedQueryVariables
>;
export const SubscriptionListDocument = gql`
  query subscriptionList($page: Int!, $pageSize: Int!) {
    subscriptionList(page: $page, pageSize: $pageSize) {
      errors
      total
      edge {
        network
        address
        createdAt
        isViewed
        hasResult
      }
    }
  }
`;

/**
 * __useSubscriptionListQuery__
 *
 * To run a query within a React component, call `useSubscriptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSubscriptionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionListQuery,
    SubscriptionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionListQuery, SubscriptionListQueryVariables>(
    SubscriptionListDocument,
    options,
  );
}
export function useSubscriptionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionListQuery,
    SubscriptionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionListQuery, SubscriptionListQueryVariables>(
    SubscriptionListDocument,
    options,
  );
}
export function useSubscriptionListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SubscriptionListQuery,
    SubscriptionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubscriptionListQuery, SubscriptionListQueryVariables>(
    SubscriptionListDocument,
    options,
  );
}
export type SubscriptionListQueryHookResult = ReturnType<typeof useSubscriptionListQuery>;
export type SubscriptionListLazyQueryHookResult = ReturnType<
  typeof useSubscriptionListLazyQuery
>;
export type SubscriptionListSuspenseQueryHookResult = ReturnType<
  typeof useSubscriptionListSuspenseQuery
>;
export type SubscriptionListQueryResult = Apollo.QueryResult<
  SubscriptionListQuery,
  SubscriptionListQueryVariables
>;
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserListDocument = gql`
  query userList($page: Int!, $pageSize: Int!) {
    userList(page: $page, pageSize: $pageSize) {
      total
      edge {
        ...UserFields
      }
      errors
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserListQuery, UserListQueryVariables>(
    UserListDocument,
    options,
  );
}
export function useUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(
    UserListDocument,
    options,
  );
}
export function useUserListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserListQuery, UserListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserListQuery, UserListQueryVariables>(
    UserListDocument,
    options,
  );
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListSuspenseQueryHookResult = ReturnType<typeof useUserListSuspenseQuery>;
export type UserListQueryResult = Apollo.QueryResult<
  UserListQuery,
  UserListQueryVariables
>;
export const ActivateDocument = gql`
  mutation activate($input: ActivateInput!) {
    activate(input: $input) {
      state
      errors
    }
  }
`;
export type ActivateMutationFn = Apollo.MutationFunction<
  ActivateMutation,
  ActivateMutationVariables
>;

/**
 * __useActivateMutation__
 *
 * To run a mutation, you first call `useActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMutation, { data, loading, error }] = useActivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateMutation, ActivateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateMutation, ActivateMutationVariables>(
    ActivateDocument,
    options,
  );
}
export type ActivateMutationHookResult = ReturnType<typeof useActivateMutation>;
export type ActivateMutationResult = Apollo.MutationResult<ActivateMutation>;
export type ActivateMutationOptions = Apollo.BaseMutationOptions<
  ActivateMutation,
  ActivateMutationVariables
>;
export const CreateDocument = gql`
  mutation create($input: CreateInput!) {
    create(input: $input) {
      email
      permissions
      password
      errors
    }
  }
`;
export type CreateMutationFn = Apollo.MutationFunction<
  CreateMutation,
  CreateMutationVariables
>;

/**
 * __useCreateMutation__
 *
 * To run a mutation, you first call `useCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMutation, { data, loading, error }] = useCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMutation, CreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMutation, CreateMutationVariables>(
    CreateDocument,
    options,
  );
}
export type CreateMutationHookResult = ReturnType<typeof useCreateMutation>;
export type CreateMutationResult = Apollo.MutationResult<CreateMutation>;
export type CreateMutationOptions = Apollo.BaseMutationOptions<
  CreateMutation,
  CreateMutationVariables
>;
export const GeneratePasswordDocument = gql`
  mutation generatePassword($input: GeneratePasswordInput!) {
    generatePassword(input: $input) {
      password
      errors
    }
  }
`;
export type GeneratePasswordMutationFn = Apollo.MutationFunction<
  GeneratePasswordMutation,
  GeneratePasswordMutationVariables
>;

/**
 * __useGeneratePasswordMutation__
 *
 * To run a mutation, you first call `useGeneratePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePasswordMutation, { data, loading, error }] = useGeneratePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePasswordMutation,
    GeneratePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GeneratePasswordMutation, GeneratePasswordMutationVariables>(
    GeneratePasswordDocument,
    options,
  );
}
export type GeneratePasswordMutationHookResult = ReturnType<
  typeof useGeneratePasswordMutation
>;
export type GeneratePasswordMutationResult =
  Apollo.MutationResult<GeneratePasswordMutation>;
export type GeneratePasswordMutationOptions = Apollo.BaseMutationOptions<
  GeneratePasswordMutation,
  GeneratePasswordMutationVariables
>;
export const SetUserPermissionsDocument = gql`
  mutation setUserPermissions($input: SetPermissionsInput!) {
    setUserPermissions(input: $input) {
      errors
    }
  }
`;
export type SetUserPermissionsMutationFn = Apollo.MutationFunction<
  SetUserPermissionsMutation,
  SetUserPermissionsMutationVariables
>;

/**
 * __useSetUserPermissionsMutation__
 *
 * To run a mutation, you first call `useSetUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPermissionsMutation, { data, loading, error }] = useSetUserPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserPermissionsMutation,
    SetUserPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserPermissionsMutation,
    SetUserPermissionsMutationVariables
  >(SetUserPermissionsDocument, options);
}
export type SetUserPermissionsMutationHookResult = ReturnType<
  typeof useSetUserPermissionsMutation
>;
export type SetUserPermissionsMutationResult =
  Apollo.MutationResult<SetUserPermissionsMutation>;
export type SetUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  SetUserPermissionsMutation,
  SetUserPermissionsMutationVariables
>;
export const DeleteDocument = gql`
  mutation delete($input: DeleteInput!) {
    delete(input: $input) {
      state
      errors
    }
  }
`;
export type DeleteMutationFn = Apollo.MutationFunction<
  DeleteMutation,
  DeleteMutationVariables
>;

/**
 * __useDeleteMutation__
 *
 * To run a mutation, you first call `useDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMutation, { data, loading, error }] = useDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMutation, DeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMutation, DeleteMutationVariables>(
    DeleteDocument,
    options,
  );
}
export type DeleteMutationHookResult = ReturnType<typeof useDeleteMutation>;
export type DeleteMutationResult = Apollo.MutationResult<DeleteMutation>;
export type DeleteMutationOptions = Apollo.BaseMutationOptions<
  DeleteMutation,
  DeleteMutationVariables
>;
export const namedOperations = {
  Query: {
    billingKeyList: 'billingKeyList',
    explorerFindBlockByHash: 'explorerFindBlockByHash',
    explorerFindTransactionsInBlockByHash: 'explorerFindTransactionsInBlockByHash',
    explorerFindAddressByHash: 'explorerFindAddressByHash',
    explorerFindTransactionsByAddress: 'explorerFindTransactionsByAddress',
    explorerFindTransactionsByCluster: 'explorerFindTransactionsByCluster',
    explorerFindTransactionsByAddressCount: 'explorerFindTransactionsByAddressCount',
    explorerFindTransactionsByClusterCount: 'explorerFindTransactionsByClusterCount',
    explorerFindMentionsForAddress: 'explorerFindMentionsForAddress',
    explorerRisk: 'explorerRisk',
    explorerFindTransactionByHash: 'explorerFindTransactionByHash',
    explorerIncomingAddressListByTransaction: 'explorerIncomingAddressListByTransaction',
    explorerOutcomingAddressListByTransaction:
      'explorerOutcomingAddressListByTransaction',
    explorerFindBlockByTransaction: 'explorerFindBlockByTransaction',
    explorerFindBlockByNumber: 'explorerFindBlockByNumber',
    explorerFindTransactionsInBlock: 'explorerFindTransactionsInBlock',
    explorerFindTransactionsWithBlock: 'explorerFindTransactionsWithBlock',
    explorerCluster: 'explorerCluster',
    explorerFindWalletAddresses: 'explorerFindWalletAddresses',
    explorerFindWalletAddressesCount: 'explorerFindWalletAddressesCount',
    explorerClusterForAddress: 'explorerClusterForAddress',
    explorerAddress: 'explorerAddress',
    explorerAddressActivity: 'explorerAddressActivity',
    explorerAddressTransactionStats: 'explorerAddressTransactionStats',
    explorerAddressCategories: 'explorerAddressCategories',
    explorerAddressChart: 'explorerAddressChart',
    explorerMarkup: 'explorerMarkup',
    explorerFindMention: 'explorerFindMention',
    mentionSourceList: 'mentionSourceList',
    graphTransactionsBetweenAddress: 'graphTransactionsBetweenAddress',
    graphTransactionsBetweenAddressAndCluster:
      'graphTransactionsBetweenAddressAndCluster',
    graphTransactionsBetweenClusterAndCluster:
      'graphTransactionsBetweenClusterAndCluster',
    graphRelationBetweenAddresses: 'graphRelationBetweenAddresses',
    graphStatsAddressByHash: 'graphStatsAddressByHash',
    graphRiskAddressByHash: 'graphRiskAddressByHash',
    graphCluster: 'graphCluster',
    graphList: 'graphList',
    graphByUUID: 'graphByUUID',
    me: 'me',
    subscribed: 'subscribed',
    subscriptionList: 'subscriptionList',
    user: 'user',
    userList: 'userList',
  },
  Mutation: {
    billingAddPacket: 'billingAddPacket',
    billingKeyCreate: 'billingKeyCreate',
    billingKeyRemove: 'billingKeyRemove',
    billingAnalyzerSubscribe: 'billingAnalyzerSubscribe',
    explorerMarkupCreate: 'explorerMarkupCreate',
    graphUpdate: 'graphUpdate',
    graphSave: 'graphSave',
    graphDelete: 'graphDelete',
    graphRename: 'graphRename',
    login: 'login',
    explorerMentionRemove: 'explorerMentionRemove',
    refreshToken: 'refreshToken',
    registration: 'registration',
    registrationConfirm: 'registrationConfirm',
    restore: 'restore',
    restoreCheck: 'restoreCheck',
    restoreConfirm: 'restoreConfirm',
    subscribe: 'subscribe',
    unsubscribe: 'unsubscribe',
    activate: 'activate',
    create: 'create',
    generatePassword: 'generatePassword',
    setUserPermissions: 'setUserPermissions',
    delete: 'delete',
  },
  Fragment: {
    BillingKeyFields: 'BillingKeyFields',
    BillingFields: 'BillingFields',
    NodeFields: 'NodeFields',
    MentionFields: 'MentionFields',
    BlockchainBlockFields: 'BlockchainBlockFields',
    FindAddressByHashNodeFields: 'FindAddressByHashNodeFields',
    CalculatedRiskFields: 'CalculatedRiskFields',
    RiskFields: 'RiskFields',
    ClusterFields: 'ClusterFields',
    AddressByTransactionFields: 'AddressByTransactionFields',
    AddressFields: 'AddressFields',
    AddressTransactionFields: 'AddressTransactionFields',
    TransactionFields: 'TransactionFields',
    UserFields: 'UserFields',
    JwtFields: 'JwtFields',
    TransactionBetweenFields: 'TransactionBetweenFields',
    GraphFields: 'GraphFields',
  },
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
