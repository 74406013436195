import React, { FC, PropsWithChildren } from 'react';
import * as gqlTypes from '@apolloGenerated';
import { hasPermission } from '@utils/auth';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../../../router';
import { ProfileControl } from '../ProfileControl/ProfileControl';
import {
  CodeIcon,
  CompassIcon,
  Divider,
  EditIcon,
  Header as HeaderUI,
  InfoIcon,
  NavItem,
  SwapIcon,
  useRouterPath,
} from '@rubin-dev/goblin';
import { LangSelect, Logo, InstructionsSelect } from '@shared/ui';
import { SUBSCRIPTION_EXPLORER_MARKUP, USER_ADMIN } from '@constants/permissions.const';

export type HeaderProps = {
  user?: Omit<gqlTypes.User, 'permissions' | 'billing'>;
  dense?: boolean;
};

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ user, dense }) => {
  const { checkActivePath } = useRouterPath();
  const { t } = useTranslation();

  return (
    <HeaderUI
      dense={dense}
      homeLink={RouterName.Analyzer}
      logoSlot={<Logo dense />}
      navigationSlot={
        <>
          <NavItem
            to={RouterName.Analyzer}
            icon={CompassIcon}
            active={checkActivePath(RouterName.Analyzer)}
            fullWidth
            hideLabel={dense}
          >
            {t('titles.analyzer')}
          </NavItem>
          {user && hasPermission(SUBSCRIPTION_EXPLORER_MARKUP) && (
            <NavItem
              to={RouterName.Markup}
              icon={CodeIcon}
              active={checkActivePath(RouterName.Markup)}
              fullWidth
              hideLabel={dense}
            >
              {t('titles.markup')}
            </NavItem>
          )}
          {user && hasPermission('api.dashboard') && (
            <NavItem
              to={RouterName.Transactions}
              icon={SwapIcon}
              active={checkActivePath(RouterName.Transactions)}
              fullWidth
              hideLabel={dense}
            >
              {t('links.api')}
            </NavItem>
          )}
          <Divider color="var(--surface-4)" />

          {hasPermission(USER_ADMIN) && (
            <>
              <NavItem
                to={RouterName.UserList}
                icon={InfoIcon}
                active={checkActivePath(RouterName.UserList)}
                fullWidth
                hideLabel={dense}
              >
                {t('titles.info')}
              </NavItem>
            </>
          )}
        </>
      }
      bottomSlot={
        <>
          <InstructionsSelect />
          <LangSelect />
          <ProfileControl />
        </>
      }
    />
  );
};
