import React, { FC, useRef, useState } from 'react';
import styles from './styles.module.scss';
import {
  Dropdown,
  DropdownItem,
  EditIcon,
  MoreHorizontalIcon,
  PropsWithNativeTypes,
  TrashIcon,
  Typography,
  useOnClickOutside,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { TableType } from '@graph/types';
import { useGraphAnnotation } from '@graph/libs/hooks';

export type AnnotationProps = {
  text: string;
  hash: string;
  parentHash: string;
  type: TableType.Transaction | TableType.Address;
  color?: string;
};
export const Annotation: FC<PropsWithNativeTypes<AnnotationProps, HTMLDivElement>> = ({
  text,
  type,
  hash,
  parentHash,
  color,
  className,
  style,
  ...rest
}): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    onAddNodeAnnotation,
    onAddLineAnnotation,
    onRemoveNodeAnnotation,
    onRemoveLineAnnotation,
    onUpdateColorLineAnnotation,
    onUpdateColorNodeAnnotation,
  } = useGraphAnnotation();
  const [isOpen, setIsOpen] = useState<boolean>();

  const handleEditAnnotation = () => {
    setIsOpen(false);
    switch (type) {
      case TableType.Address:
        return onAddNodeAnnotation(parentHash, hash);
      case TableType.Transaction:
        return onAddLineAnnotation(parentHash);
    }
  };
  const handleRemoveAnnotation = () => {
    setIsOpen(false);
    switch (type) {
      case TableType.Address:
        return onRemoveNodeAnnotation(hash, parentHash);
      case TableType.Transaction:
        return onRemoveLineAnnotation(hash, parentHash);
    }
  };
  const handleChangeColorAnnotation = (color: string) => {
    setIsOpen(false);
    switch (type) {
      case TableType.Address:
        return onUpdateColorNodeAnnotation(hash, parentHash, color);
      case TableType.Transaction:
        return onUpdateColorLineAnnotation(hash, parentHash, color);
    }
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div
      className={cx(styles.annotation, className)}
      style={{ borderColor: color, ...style }}
      {...rest}
    >
      <Typography
        variant="body-14"
        color="on-surface-secondary-2"
        className={styles.annotation__content}
      >
        {text}
      </Typography>
      <div ref={dropdownRef} className={styles.annotation__control}>
        <Dropdown
          value={isOpen}
          onChange={setIsOpen}
          className={styles.annotation__dropdown}
          hideOnClickOutside
          targetSlot={
            <MoreHorizontalIcon
              className={cx(
                styles.annotation__moreIcon,
                isOpen && styles.annotation__moreIcon_active,
              )}
              onClick={() => setIsOpen(true)}
            />
          }
          placement="bottom-start"
          zIndex={1000}
        >
          <div className={styles.annotation__wrap}>
            <DropdownItem
              label={t('buttons.edit')}
              icon={EditIcon}
              fullWidth
              onClick={handleEditAnnotation}
            />
            <DropdownItem
              label={t('buttons.delete')}
              icon={TrashIcon}
              fullWidth
              onClick={handleRemoveAnnotation}
            />
            {/*<div className={styles.annotation__hr}>*/}
            {/*  <Divider />*/}
            {/*</div>*/}
            {/*<ColorMenu*/}
            {/*  activeColor={color as GraphCustomColor}*/}
            {/*  onSelect={handleChangeColorAnnotation}*/}
            {/*/>*/}
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
